import React from 'react'

import CartItem from './cart-item'

export default ({ items, checkoutPage }) => {
  if (!items) return <div>No items in your shopping cart </div>
  return (
    <>
      {items.map(item => {
        const {
          img_url,
          dimension,
          color,
          sku,
          quantity,
          price,
          product
        } = item
        console.log('item in cartItem: ', item)
        return (
          <CartItem
            key={sku}
            sku={sku}
            thumbnail={img_url}
            link={`/product/${sku}`}
            name={product}
            size={dimension}
            color={color}
            quantity={quantity}
            price={price || 0}
            checkoutPage={checkoutPage}
          >
            <div className="font-size-sm">
              <span className="text-muted mr-2">Size:</span>
              {dimension}
            </div>
            <div className="font-size-sm">
              <span className="text-muted mr-2">Color:</span>
              {color}
            </div>
          </CartItem>
        )
      })}
    </>
  )
}
