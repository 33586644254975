import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { removeFromCart, updateQuantity } from '../../actions/cart'

const CartItem = ({
  sku,
  name,
  thumbnail,
  link,
  price,
  children,
  quantity,
  updateProductQuantity,
  removeProductFromCart,
  checkoutPage
}) => {
  const [quantityState, setQuantityState] = useState(quantity)
  const removeItem = () => {
    removeProductFromCart(sku)
  }

  const changeQuantity = () => {
    updateProductQuantity(sku, quantityState)
  }

  return (
    <div className="d-sm-flex justify-content-between my-4 pb-4 border-bottom">
      <div className="media d-block d-sm-flex text-center text-sm-left">
        <a className="cart-item-thumb mx-auto mr-sm-4" href={link}>
          <img src={thumbnail} alt="Product" />
        </a>
        <div className="media-body pt-3">
          <h3 className="product-card-title font-weight-semibold border-0 pb-0">
            <a href={link}>{name}</a>
          </h3>
          {children}
          <div className="font-size-lg text-primary pt-2">${price}</div>
        </div>
      </div>
      {checkoutPage ? (
        ''
      ) : (
        <div
          className="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left"
          style={{ maxWidth: '10rem' }}
        >
          <div className="form-group mb-2">
            <label htmlFor="quantity1" defaultValue="0">
              Quantity
            </label>
            <input
              className="form-control form-control-sm"
              style={{ height: 'calc(1.5em + 0.5rem + 12px)' }}
              type="number"
              id="quantity1"
              value={quantityState}
              onChange={e => setQuantityState(e.target.value)}
            />
          </div>
          <button
            className="btn btn-outline-secondary btn-sm btn-block mb-2"
            type="button"
            onClick={changeQuantity}
          >
            <i
              className="icon ion-md-refresh mr-2"
              style={{ fontSize: '1.45em' }}
            />
            Update cart
          </button>
          <button
            className="btn btn-outline-danger btn-sm btn-block mb-2"
            type="button"
            onClick={removeItem}
          >
            <i
              className="icon ion-md-trash mr-2"
              style={{ fontSize: '1.45em' }}
            />
            Remove
          </button>
        </div>
      )}
    </div>
  )
}

export default connect(
  null,
  {
    updateProductQuantity: updateQuantity,
    removeProductFromCart: removeFromCart
  }
)(CartItem)
