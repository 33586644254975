import React, { useState } from 'react'

export default ({ filteredProducts, setFilteredProducts, products }) => {
  const [selectedManufacturers, setSelectedManufacturers] = useState([])

  const manufacturers = []

  products.forEach(product => {
    if (
      manufacturers.filter(manu => {
        return manu.name === product.manufacturer
      }).length
    ) {
      manufacturers.find(manu => manu.name === product.manufacturer).count++
    } else {
      manufacturers.push({ name: product.manufacturer, count: 1 })
    }
  })

  const filterByManufacturer = manufacturer => {
    let newListOfManufacturers = selectedManufacturers

    console.log('manufacturer ', manufacturer)
    if (selectedManufacturers.includes(manufacturer)) {
      newListOfManufacturers = newListOfManufacturers.filter(manu => {
        console.log('manu inside of filter ', manu)
        return manu !== manufacturer
      })
      console.log(
        'new list of manus inside of if block ',
        newListOfManufacturers
      )
    } else {
      newListOfManufacturers.push(manufacturer)
    }

    console.log('New List of Manus ', newListOfManufacturers)
    setSelectedManufacturers(newListOfManufacturers)

    const newFilteredProducts = newListOfManufacturers.length
      ? products.filter(product => {
          return newListOfManufacturers.includes(product.manufacturer)
        })
      : products

    console.log('New List of Filtered Prods ', newFilteredProducts)
    setFilteredProducts(newFilteredProducts)
  }

  return (
    <>
      <h3 className="widget-title">Filter by manufacturer</h3>
      {manufacturers.map(manufacturer => {
        return (
          <div className="custom-control custom-checkbox mb-1">
            <input
              className="custom-control-input"
              type="checkbox"
              id={manufacturer.name}
              onClick={() => filterByManufacturer(manufacturer.name)}
            />
            <label className="custom-control-label" htmlFor={manufacturer.name}>
              {manufacturer.name}
              <span className="text-muted ml-1">({manufacturer.count})</span>
            </label>
          </div>
        )
      })}
    </>
  )
}
