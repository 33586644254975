import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Link, NavLink } from 'react-router-dom'
import { Toast } from 'react-bootstrap'
import queryString from 'query-string'

import config from './navmenu.config'
import Icon from '../ioicon'
import IconLibrary from '../../util/IconLibrary'

import SigninSlider from '../signinSlider'
import ShoppingCartIcon from './shoppingCartMenuItem'
import SignOutButton from '../signout'

const getPageItem = ({ name, link }) => (
  <li key={name}>
    <NavLink to={link}>
      <Icon name="arrow-dropright" className="mr-1" />
      <span className="font-size-sm">{name}</span>
    </NavLink>
  </li>
)

const mapColumnPages = pages => {
  return (
    <div className="widget widget-links">
      <ul>{pages.map(page => getPageItem(page))}</ul>
    </div>
  )
}

const mapColumn = item => {
  return item.subCategories.map(subCategory => {
    const { name, headerImage, pages } = subCategory

    return (
      <div key={name} className="dropdown-column">
        <div
          className="bg-position-center bg-no-repeat bg-size-cover text-center px-3 py-4 mb-3"
          style={{ backgroundImage: `url(${headerImage})` }}
        >
          <h3 className="h5 text-white text-shadow my-3">{name}</h3>
        </div>
        {mapColumnPages(pages)}
      </div>
    )
  })
}

// const mapMainMenuPage = itemArray => {
//   const item = itemArray[0]
//   return (
//     <li className=" nav-item dropdown mega-dropdown dropdown-more">
//       <span
//         className="nav-link dropdown-toggle btn-link"
//         data-toggle="dropdown"
//       >
//         {item.name}
//         {IconLibrary.Elipsis()}
//         <i data-feather="more-horizontal" />
//       </span>
//       <div className="dropdown-menu">
//         <div className="dropdown-inner">{mapColumn(item)}</div>
//       </div>
//     </li>
//   )
// }

const mapItemSubcategories = subCategories => {
  return subCategories.map(subCategory => (
    <div key={subCategory.name} className="dropdown-column">
      <div className="widget widget-links">
        <h3 className="widget-title">{subCategory.name}</h3>
        <ul>{subCategory.pages.map(page => getPageItem(page))}</ul>
      </div>
    </div>
  ))
}

const mapSubPages = itemArray => {
  return itemArray.map(item => (
    <li key={item.name} className="nav-item dropdown mega-dropdown">
      <Link
        className="nav-link dropdown-toggle"
        to={item.link}
        data-toggle="dropdown"
      >
        {item.name}
      </Link>
      {item.subCategories ? (
        <div className="dropdown-menu">
          <div className="dropdown-inner">
            {mapItemSubcategories(item.subCategories)}
          </div>
        </div>
      ) : (
        ''
      )}
    </li>
  ))
}

const mapMenu = () => {
  const { items } = config
  const [mainMenuPage, otherPages] = _.partition(items, 'isMain')
  return (
    <div className="collapse navbar-collapse" id="menu">
      <ul className="navbar-nav">
        {/* {mapMainMenuPage(mainMenuPage)} */}
        {mapSubPages(otherPages)}
      </ul>
    </div>
  )
}

const NavMenu = ({ username, courier }) => {
  const [showSignin, setShowSignin] = useState(false)
  const showSlider = () => {
    setShowSignin(!showSignin)
  }

  const [showToast, setShowToast] = useState(false)
  const handleShowToast = () => setShowToast(true)

  const [toastMessage, setToastMessage] = useState('')

  console.log('courier in nav, ', courier)
  // const query = queryString.parse(location.search)
  // console.log('query in nav: ', query)

  return (
    <>
      {courier === '1' ? (
        <header className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid navbar-inner">
            <a
              className="navbar-brand brand"
              href="https://www.materialdrop.com"
            >
              <img
                src="/img/MDrop_Full_1.png"
                width="180px"
                alt="MDrop logo"
                aria-label="Mdrop Logo"
              />
            </a>
          </div>
        </header>
      ) : (
        <>
          {showSignin ? (
            <SigninSlider
              showSlider={showSlider}
              handleShowToast={handleShowToast}
              setToastMessage={setToastMessage}
            />
          ) : null}
          <div
            aria-live="polite"
            aria-atomic="true"
            className="toast-container toast-top-center"
          >
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Header
                className="bg-success text-white"
                closeButton={false}
                style={{ padding: '10px' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check-circle mr-2"
                  style={{ width: '1.25rem', height: '1.25rem' }}
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
                <span className="font-weight-semibold mr-auto">
                  {toastMessage}
                </span>
              </Toast.Header>
              <Toast.Body>This item was added to your cart.</Toast.Body>
            </Toast>
          </div>
          <header className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid navbar-inner">
              <a
                className="navbar-brand brand"
                href="https://www.materialdrop.com"
              >
                <img
                  src="/img/MDrop_Full_1.png"
                  width="180px"
                  alt="MDrop logo"
                  aria-label="Mdrop Logo"
                />
              </a>
              {/* {mapMenu()} */}

              <div className="navbar-btns">
                <div className="navbar-btns-inner">
                  <div
                    className="navbar-toggler navbar-btn collapsed"
                    data-toggle="collapse"
                    data-target="#menu"
                  >
                    <i className="mx-auto mb-1" data-feather="menu" />
                    Menu
                  </div>
                  <Link className="navbar-btn" to="/search">
                    {IconLibrary.Search()}
                    <i className="mx-auto mb-1" data-feather="search" />
                    Search
                  </Link>
                  {!!username ? (
                    <SignOutButton
                      setToastMessage={setToastMessage}
                      handleShowToast={handleShowToast}
                    />
                  ) : (
                    <a
                      className="navbar-btn navbar-collapse-hidden"
                      href="#offcanvas-account"
                      data-toggle="offcanvas"
                      onClick={showSlider}
                    >
                      {IconLibrary.SignIn()}
                      <i className="mx-auto mb-1" data-feather="log-in" />
                      Sign In/Up
                    </a>
                  )}
                  <a
                    className="navbar-btn"
                    href="/checkout/cart"
                    data-toggle="offcanvas"
                  >
                    <ShoppingCartIcon />
                  </a>
                </div>
              </div>
            </div>
          </header>
        </>
      )}
    </>
  )
}

export default connect(state => {
  const { username } = state.user
  return { username }
})(NavMenu)
