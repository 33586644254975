import React from 'react'
import { Link } from 'react-router-dom'

const mapBreadcrumbItem = ({ name, link, isActive }) => {
  return (
    <li key={name} className={`breadcrumb-item ${isActive ? 'active' : ''}`}>
      <Link to={link}>{name}</Link>
    </li>
  )
}

export default ({ breadcrumbItemMatrix }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="mt-n1 mr-1">
          <i data-feather="home" />
        </li>
        <li className="breadcrumb-item">
          <Link to="/">
            <i className="icon ion-md-home mr-1" />
            Home
          </Link>
        </li>
        {breadcrumbItemMatrix.map(item => mapBreadcrumbItem(item))}
      </ol>
    </nav>
  )
}
