import React from 'react'
import { connect } from 'react-redux'

import LeftPane from '../../components/checkout/left-pane'
import RightPane from '../../components/checkout/right-pane'
import CheckoutLayout from '../../components/checkout/layout'
import Header from '../../components/checkout/header'
import OrderSummary from '../../components/checkout/order-summary'
// import DeliveryTimeChooser from '../../components/checkout/delivery-time-chooser'
import { getDateString, getHour } from '../../util/date'
import { updateForm } from '../../actions/form'

const Delivery = ({ dispatchField, history }) => {
  const currentDate = new Date()
  const currentHour = currentDate.getHours() + 1
  console.log(`${getDateString(currentDate)} ${currentHour}:00:00`)
  return (
    <CheckoutLayout currentPage="Delivery">
      {window.scrollTo(0, 0)}
      <LeftPane>
        <Header>Confirm delivery</Header>
        <div
          style={{
            border: '1px solid #E1E1E1',
            padding: '15px',
            marginTop: '10px',
            marginBottom: '20px'
          }}
        >
          {' '}
          <span className="text-muted">Estimated Delivery Time </span>
          <h1 className="page-title">
            <span>Within 2 Hours</span>
          </h1>
          <h2 className="h1 text-primary font-weight-light pt-3 pb-2">
            $26.50
          </h2>
        </div>
        {/* <DeliveryTimeChooser
          data={[
            {
              date: getDateString(currentDate),
              times: [
                { text: 'Within 1 Hour', cost: '22.00' },
                { text: 'Within 2 Hours', cost: '18.00' },
                {
                  text: `${getHour(currentHour + 3)}-${getHour(
                    currentHour + 4
                  )}`,
                  cost: '18.00'
                },
                {
                  text: `${getHour(currentHour + 5)}-${getHour(
                    currentHour + 6
                  )}`,
                  cost: '18.00'
                },
                {
                  text: `${getHour(currentHour + 7)}-${getHour(
                    currentHour + 8
                  )}`,
                  cost: '18.00'
                }
              ]
            },
            {
              date: '7/9/2019',
              times: [
                { text: '9-10am', cost: '13.00' },
                { text: '10-11am', cost: '13.00' },
                { text: '11-12pm', cost: '13.00' },
                { text: '12-1pm', cost: '13.00' },
                { text: '1-2pm', cost: '13.00' },
                { text: '2-3pm', cost: '13.00' },
                { text: '3-4pm', cost: '13.00' }
              ]
            },
            {
              date: '7/10/2019',
              times: [
                { text: '9-10am', cost: '12.00' },
                { text: '10-11am', cost: '12.00' },
                { text: '11-12pm', cost: '12.00' },
                { text: '12-1pm', cost: '12.00' },
                { text: '1-2pm', cost: '12.00' },
                { text: '2-3pm', cost: '12.00' },
                { text: '3-4pm', cost: '12.00' }
              ]
            },
            {
              date: '7/11/2019',
              times: [
                { text: '9-10am', cost: '11.00' },
                { text: '10-11am', cost: '11.00' },
                { text: '11-12pm', cost: '11.00' },
                { text: '12-1pm', cost: '11.00' },
                { text: '1-2pm', cost: '11.00' },
                { text: '2-3pm', cost: '11.00' },
                { text: '3-4pm', cost: '11.00' }
              ]
            },
            {
              date: '7/12/2019',
              times: [
                { text: '9-10am', cost: '10.00' },
                { text: '10-11am', cost: '10.00' },
                { text: '11-12pm', cost: '10.00' },
                { text: '12-1pm', cost: '10.00' },
                { text: '1-2pm', cost: '10.00' },
                { text: '2-3pm', cost: '10.00' },
                { text: '3-4pm', cost: '10.00' }
              ]
            },
            {
              date: '7/13/2019',
              times: [
                { text: '9-10am', cost: '5.00' },
                { text: '10-11am', cost: '5.00' },
                { text: '11-12pm', cost: '5.00' },
                { text: '12-1pm', cost: '5.00' },
                { text: '1-2pm', cost: '5.00' },
                { text: '2-3pm', cost: '5.00' },
                { text: '3-4pm', cost: '5.00' }
              ]
            }
          ]}
        /> */}

        <div className="d-flex pt-2">
          <div className="w-50 pr-3">
            <a
              className="btn btn-outline-secondary btn-block"
              href="/checkout/details"
            >
              <i className="icon ion-md-arrow-dropleft mr-2" />
              Back
            </a>
          </div>
          <div className="w-50 pl-2">
            <a
              className="btn btn-primary btn-block"
              href="#"
              onClick={() => {
                dispatchField(
                  'deliveryTime',
                  `${getDateString(currentDate)} ${currentHour}:00:00`
                )
                history.push('/checkout/payment')
              }}
            >
              <span className="d-none d-sm-inline">Proceed to payment</span>
              <span className="d-inline d-sm-none">Next</span>
              <i className="icon ion-md-arrow-dropright ml-2" />
            </a>
          </div>
        </div>
      </LeftPane>
      <RightPane>
        <OrderSummary />
      </RightPane>
    </CheckoutLayout>
  )
}

export default connect(
  state => ({ form: state.form }),
  { dispatchField: updateForm }
)(Delivery)
