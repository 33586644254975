import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { API_URL } from '../../config'

import Layout from '../../components/layout/layout'
import { getAuthHeader } from '../../services/service-helper'

const Complete = ({ form, match }) => {
  const chosenOrder = match.params.id

  // useEffect(() => {
  //   const effectFunc = () => {
  //     setTimeout(() => {
  //       axios({
  //         method: 'put',
  //         url: `${API_URL}orders/${chosenOrder}/status/shopping_completed`,
  //         headers: getAuthHeader()
  //       })
  //         .then(() => console.log('success'))
  //         .catch(() => 'error completing shopping')
  //     }, 2000)
  //   }
  //   effectFunc()
  // }, [])

  return (
    <Layout courier="1">
      <div className="container pb-5 mb-sm-4">
        <div className="pt-5">
          <div className="card py-3 mt-sm-3">
            <div className="card-body text-center">
              <h3 className="h4 pb-3">Thank you for your order!</h3>
              <p className="mb-2">
                Your order has been placed and will be processed as soon as
                possible.
              </p>
              <p className="mb-2">
                Make sure you make note of your order number, which is
                <strong> {chosenOrder}.</strong>
              </p>
              <p>
                You will be receiving an email shortly with confirmation of your
                order. <em>You can now:</em>
              </p>
              {/* <a className="btn btn-outline-secondary mt-3 mr-3" href="/shop">
                Shop More
              </a> */}
              <a
                className="btn btn-primary mt-3"
                href={`/order-tracking/${chosenOrder}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-map-pin"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
                &nbsp; Track Order
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default connect(state => ({ form: state.form }))(Complete)
