import React from 'react'
import { connect } from 'react-redux'

import IconLibrary from '../../util/IconLibrary'
import { productItemsWithQuantity } from '../../selectors/productQuantitySelector'
import { cartCostSelector } from '../../selectors/cartCostSelector'

const ShoppingCartMenuItem = ({ items, cost }) => {
  return (
    <span className="d-block position-relative">
      <span className="navbar-btn-badge bg-primary text-light">{items.length || 0}</span>
      {IconLibrary.ShoppingCart()}
      <i className="mx-auto mb-1" data-feather="shopping-cart" />${cost}
    </span>
  )
}

export default connect(state => ({
  items: productItemsWithQuantity(state),
  cost: cartCostSelector(state)
}))(ShoppingCartMenuItem)
