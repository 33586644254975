import React, { useState } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import LeftPane from '../../components/checkout/left-pane'
import RightPane from '../../components/checkout/right-pane'
import CheckoutLayout from '../../components/checkout/layout'
import Header from '../../components/checkout/header'
import ShoppingCart from '../../components/checkout/shopping-cart'
import OrderSummary from '../../components/checkout/order-summary'
import { productItemsWithQuantity } from '../../selectors/productQuantitySelector'
import { createOrder } from '../../services/order'
import { createPayment } from '../../services/payment'
import { updateForm } from '../../actions/form'
import { removeFromCart } from '../../actions/cart'

const Review = ({
  items,
  form,
  dispatchField,
  history,
  removeFromCart,
  location
}) => {
  const {
    deliveryFirstName,
    deliveryLastName,
    deliveryAddress1,
    deliveryAddress2,
    deliveryCity,
    deliveryZip,
    deliveryPhone,
    company,
    billingFirstName,
    billingLastName,
    billingAddress1,
    billingAddress2,
    billingCity,
    billingZip,
    billingPhone,
    last4,
    deliveryPo,
    token,
    deliveryTime
  } = form

  console.log('TCL: deliveryFirstName', deliveryFirstName)
  const query = queryString.parse(location.search)
  const {
    courier,
    cost,
    facility,
    address,
    po,
    name,
    phone,
    lockercode,
    lockernumber,
    email,
    pickupaddress
  } = query
  console.log('TCL: phone', phone)
  console.log('TCL: name', name)
  console.log('TCL: po', po)
  console.log('TCL: address', address)
  console.log('TCL: facility', facility)
  console.log('TCL: cost', cost)
  console.log('TCL: courier', courier)
  console.log('Inside Review', courier === '1')
  console.log('lockercode', lockercode)
  console.log('lockernumber', lockernumber)
  let dt = new Date()
  dt.setHours(dt.getHours() + 2)
  console.log('time: ', new Date(), 'future 2 hrs: ', dt)

  //facility=
  //address=
  //po=
  //name=
  //phone=
  //lockercode=
  //lockernumber=

  const [errorMessage, setErrorMessage] = useState('')

  const completeOrder =
    courier === '1'
      ? async () => {
          try {
            const response = await createOrder(
              10,
              '',
              dt,
              address,
              'Denver',
              'CO',
              '',
              'America/Denver',
              1,
              [],
              phone,
              po,
              lockercode,
              lockernumber,
              pickupaddress,
              cost
            )
            const orderId = response.data.data.id.toString()
            dispatchField('orderId', orderId)
            const paymentInfo = await createPayment(
              orderId,
              token,
              lockercode,
              lockernumber
            )
            console.log('orderId', orderId)
            console.log('paymentInfo', paymentInfo)

            history.push(`/checkout/complete/${orderId}`)
          } catch (e) {
            console.log('There was an error processing the order', e)
            console.log('Payment details', e.status, e.body)
            setErrorMessage(
              `There was an error processing your credit card, please double check you have sufficient funds and that the account is active.
              Press back to try another card.`
            )
          }
        }
      : async () => {
          try {
            const response = await createOrder(
              10,
              '',
              deliveryTime,
              deliveryAddress1,
              deliveryCity,
              deliveryCity,
              '',
              'America/Denver',
              1,
              items.map(x => x.id),
              deliveryPhone,
              deliveryPo,
              '',
              ''
            )
            const orderId = response.data.data.id.toString()
            dispatchField('orderId', orderId)

            //if there's an invoice skip this next line
            await createPayment(orderId, token, lockercode, lockernumber)
            items.forEach(item => removeFromCart(item.sku))
            history.push(`/checkout/complete/${orderId}`)
          } catch (e) {
            console.log('There was an error processing the order')
          }
        }

  return (
    <CheckoutLayout currentPage="Final Review" courier={courier}>
      {window.scrollTo(0, 0)}
      <LeftPane className="col-xl-9 col-md-8">
        <Header>Review your order</Header>
        <ShoppingCart items={items} checkoutPage={true} />
        <div className="bg-secondary px-4 pt-4 pb-2">
          <div className="row">
            <div className="col-sm-6">
              <h4 className="h6">Delivery Info:</h4>
              <ul className="list-unstyled">
                {deliveryFirstName ? (
                  <>
                    <li>
                      <span className="text-muted">Customer:&nbsp;</span>
                      {deliveryFirstName} {deliveryLastName}
                    </li>
                    <li>
                      <span className="text-muted">Address:&nbsp;</span>
                      {deliveryAddress1}
                      {deliveryAddress2 || ''}, {deliveryCity} {deliveryZip}
                    </li>
                    <li>
                      <span className="text-muted">Phone:&nbsp;</span>

                      {deliveryPhone.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        '($1) $2-$3'
                      )}
                    </li>
                    <li>
                      <span className="text-muted">PO:&nbsp;</span>
                      {deliveryPo}
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <span className="text-muted">Customer:&nbsp;</span>
                      {name}
                    </li>
                    <li>
                      <span className="text-muted">
                        Delivery Address:&nbsp;
                      </span>
                      {address}
                    </li>
                    <li>
                      <span className="text-muted">Phone:&nbsp;</span>
                      {phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
                    </li>
                    <li>
                      <span className="text-muted">Email:&nbsp;</span>
                      {email}
                    </li>
                    <h4 className="h6 mt-3">Pickup Info:</h4>
                    <li>
                      <span className="text-muted">Pickup Address:&nbsp;</span>
                      {pickupaddress}
                    </li>
                    <li>
                      <span className="text-muted">PO:&nbsp;</span>
                      {po}
                    </li>
                    <li>
                      <span className="text-muted">Locker Number:&nbsp;</span>
                      {lockernumber}
                    </li>
                    <li>
                      <span className="text-muted">Locker Code:&nbsp;</span>
                      {lockercode}
                    </li>
                  </>
                )}
              </ul>
            </div>
            {billingFirstName ? (
              <>
                <div className="col-sm-6">
                  <h4 className="h6">Billing:</h4>
                  <ul className="list-unstyled">
                    <li>
                      <span className="text-muted">Customer:&nbsp;</span>
                      {billingFirstName} {billingLastName}
                    </li>
                    <li>
                      <span className="text-muted">Address:&nbsp;</span>
                      {billingAddress1}
                      {billingAddress2 || ''}, {billingCity} {billingZip}
                    </li>
                    <li>
                      <span className="text-muted">Phone:&nbsp;</span>
                      +1 {billingPhone}
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <h4 className="h6">Payment method:</h4>
                  <ul className="list-unstyled">
                    <li>
                      <span className="text-muted">Credit Card:&nbsp;</span>
                      **** **** **** {last4}
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        {errorMessage ? (
          <div>
            <p style={{ color: 'red' }}>{errorMessage}</p>
          </div>
        ) : (
          ''
        )}

        <div className="d-flex pt-4">
          <div className="w-50 pr-3">
            <a
              className="btn btn-outline-secondary btn-block"
              href={
                courier === '1'
                  ? `/checkout/payment?courier=1&cost=${cost}&facility=${facility}&address=${address}&po=${po}&name=${name}&phone=${phone}&lockercode=${lockercode}&lockernumber=${lockernumber}&email=${email}&pickupaddress=${pickupaddress}`
                  : '/checkout/payment'
              }
            >
              <i className="icon ion-md-arrow-dropleft mr-2" />
              Back
            </a>
          </div>
          <div className="w-50 pl-2">
            <a
              className="btn btn-primary btn-block"
              style={{ cursor: 'pointer' }}
              onClick={completeOrder}
            >
              <span className="d-none d-sm-inline">Complete order</span>
              <span className="d-inline d-sm-none">Complete</span>
              <i className="icon ion-md-arrow-dropright ml-2" />
            </a>
          </div>
        </div>
      </LeftPane>

      <RightPane>
        {courier === '1' ? (
          <OrderSummary delivery={parseFloat(cost)} />
        ) : (
          <OrderSummary />
        )}
      </RightPane>
    </CheckoutLayout>
  )
}

export default connect(
  state => ({
    form: state.form,
    items: productItemsWithQuantity(state)
  }),
  { dispatchField: updateForm, removeFromCart }
)(Review)
