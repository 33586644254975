import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../layout/layout'
import Breadcrumb from '../layout/breadcrumb'

export default ({ children, currentPage, courier }) => {
  const pages = [
    { name: 'Shopping Cart', step: 1, icon: 'cart', link: '/checkout/cart' },
    { name: 'Your Details', step: 2, icon: 'man', link: '/checkout/details' },
    { name: 'Delivery', step: 3, icon: 'mail', link: '/checkout/delivery' },
    { name: 'Payment', step: 4, icon: 'card', link: '/checkout/payment' },
    {
      name: 'Final Review',
      step: 5,
      icon: 'checkmark-circle-outline',
      link: '/checkout/review'
    }
  ]
  return (
    <Layout courier={courier}>
      <div className="page-title-wrapper" aria-label="Page title">
        <div className="container">
          <Breadcrumb
            breadcrumbItemMatrix={[
              { name: 'Shop', link: '#', isActive: false }
            ]}
          />
          <h1 className="page-title">Checkout</h1>
          <span className="d-block mt-2 text-muted" />
        </div>
      </div>
      <div className="container pb-5 mb-sm-4 mt-n2 mt-md-n3">
        {/* <div className="steps">
          <div className="steps-body">{getSteps(pages, currentPage)}</div>
        </div> */}
        <div className="row pt-4 mt-2">{children}</div>
      </div>
    </Layout>
  )
}

const getSteps = (pages, currentPageName) => {
  let foundPage = false
  return pages.map(page => {
    const { step, icon, name, link } = page
    if (!foundPage) foundPage = name === currentPageName
    return !foundPage
      ? getCompletedStep(step, icon, name, link, name === currentPageName)
      : getNonCompletedStep(step, icon, name, link, name === currentPageName)
  })
}

const getCompletedStep = (step, icon, pageName, pageLink, isActive) => {
  return (
    <Link key={pageName} className="step step-completed" to={pageLink}>
      <span className="step-indicator">
        <i className="icon ion-md-checkmark" />
      </span>
      <span className="step-icon">
        <i className={`icon ion-md-${icon}`} />
      </span>
      {`${step}. ${pageName}`}
    </Link>
  )
}

const getNonCompletedStep = (step, icon, pageName, pageLink, isActive) => {
  return (
    <Link key={pageName} className="step" to={pageLink}>
      <span className="step-icon">
        <i className={`icon ion-md-${icon}`} />
      </span>
      {`${step}. ${pageName}`}
    </Link>
  )
}
