import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import middleware from '../middleware/combineMiddleware'
import { rootReducer } from '../reducers/store'
const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  let store = createStore(persistedReducer, applyMiddleware(...middleware))
  let persistor = persistStore(store)

  if (module.hot) {
    module.hot.accept('../reducers/store', () => {
      // This fetch the new state of the above reducers.
      const nextRootReducer = require('../reducers/store').default
      store.replaceReducer(persistReducer(persistConfig, nextRootReducer))
    })
  }

  return { store, persistor }
}
