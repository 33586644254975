import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'

import { logUserIn, signUserUp } from '../actions/user'

const SignInSlider = ({
  signIn,
  signUp,
  username,
  history,
  showSlider,
  handleShowToast,
  setToastMessage
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [signInError, setSignInError] = useState('')
  const [signUpError, setSignUpError] = useState('')

  const handleSignInSubmit = async e => {
    e.preventDefault()
    try {
      await signIn(email, password)
      handleShowSlider()
      showSlider()
    } catch (error) {
      setSignInError(
        'Incorrect information, please try again or sign up for a new account'
      )
    }
  }

  const handleSignUpSubmit = async e => {
    e.preventDefault()
    //TODO: refactor all of this into Formik later
    if (password === confirmPassword) {
      try {
        await signUp(email, password, firstName, lastName, phone)
        handleShowSlider()
        showSlider()
        handleShowToast()
        setToastMessage('Please confirm your email address to sign in')
      } catch (error) {
        console.log('There was an error signing up', error)
        setSignUpError(
          "Please make sure your password is at least 10 characters, and you don't already have an account with the same email or phone number"
        )
      }
    }
  }

  const handleOnChange = setStateFunction => e => {
    setStateFunction(e.target.value)
  }

  const handleShowSlider = () => {
    const animationWrapper = document.getElementById('offcanvas-account')
    animationWrapper.classList.toggle('show')
  }

  useEffect(() => {
    handleShowSlider()
    return () => {
      handleShowSlider()
    }
  })

  return (
    <>
      <div className="offcanvas offcanvas-reverse" id="offcanvas-account">
        <div className="offcanvas-header d-flex justify-content-between align-items-center">
          <h3 className="offcanvas-title">Sign in / Create account</h3>
          <button
            className="close"
            type="button"
            data-dismiss="offcanvas"
            aria-label="Close"
            onClick={showSlider}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="offcanvas-body-inner">
            <Tabs
              defaultActiveKey="signin"
              className="nav nav-tabs nav-justified"
              role="tablist"
            >
              <Tab
                className="nav-item nav-link"
                title="Sign In"
                eventKey="signin"
              >
                <div className="tab-content pt-1">
                  <div
                    className="tab-pane fade show active"
                    id="signin"
                    role="tabpanel"
                  >
                    <form
                      className="needs-validation"
                      noValidate
                      onSubmit={handleSignInSubmit}
                    >
                      <div className="form-group">
                        <label className="" htmlFor="signin-email">
                          Email
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="email"
                            id="signin-email"
                            placeholder="Email"
                            aria-label="Email"
                            required
                            onChange={useCallback(handleOnChange(setEmail))}
                          />
                          <div className="invalid-feedback">
                            Please provide email address.
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="" htmlFor="signin-password">
                          Password
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="password"
                            id="signin-password"
                            placeholder="Password"
                            aria-label="Password"
                            required
                            onChange={useCallback(handleOnChange(setPassword))}
                          />
                          <div className="invalid-feedback">
                            Please enter a password.
                          </div>
                        </div>
                      </div>
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="remember-me"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="remember-me"
                        >
                          Remember me
                        </label>
                      </div>
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Sign In
                      </button>
                      <div>
                        <span className="text-danger">{signInError}</span>
                      </div>
                    </form>
                  </div>
                </div>
              </Tab>
              <Tab
                className="nav-item nav-link"
                title="Sign Up"
                eventKey="signup"
              >
                <div className="tab-content pt-1">
                  <div
                    className="tab-pane fade show active"
                    id="signup"
                    role="tabpanel"
                  >
                    <form
                      className="needs-validation"
                      noValidate
                      onSubmit={handleSignUpSubmit}
                    >
                      <div className="form-group">
                        <label className="" htmlFor="signup-email">
                          Email address
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          id="signup-email"
                          placeholder="Email address"
                          aria-label="Email address"
                          required
                          onChange={useCallback(handleOnChange(setEmail))}
                        />
                        <div className="invalid-feedback">
                          Please provide a valid email address.
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="" htmlFor="signup-password">
                          Password (At Least 10 characters)
                        </label>
                        <input
                          className="form-control"
                          type="password"
                          id="signup-password"
                          placeholder="Password"
                          aria-label="Password"
                          required
                          onChange={useCallback(handleOnChange(setPassword))}
                        />
                        <div className="invalid-feedback">
                          Please enter a password.
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="" htmlFor="signup-password-confirm">
                          Confirm password
                        </label>
                        <input
                          className="form-control"
                          type="password"
                          id="signup-password-confirm"
                          placeholder="Confirm password"
                          aria-label="Confirm password"
                          required
                          onChange={useCallback(
                            handleOnChange(setConfirmPassword)
                          )}
                        />
                        <div className="invalid-feedback">
                          Please confirm password.
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="" htmlFor="signup-firstName">
                          First Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="signup-firstName"
                          placeholder="First Name"
                          aria-label="First Name"
                          required
                          onChange={useCallback(handleOnChange(setFirstName))}
                        />
                        <div className="invalid-feedback">
                          Please provide a first name.
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="" htmlFor="signup-lastName">
                          Last Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="signup-lastName"
                          placeholder="Last Name"
                          aria-label="Last Name"
                          required
                          onChange={useCallback(handleOnChange(setLastName))}
                        />
                        <div className="invalid-feedback">
                          Please provide a last name.
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="" htmlFor="signup-phone">
                          Phone Number: 3031231234
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="signup-phone"
                          placeholder="Phone Number"
                          aria-label="Phone Number"
                          required
                          onChange={useCallback(handleOnChange(setPhone))}
                        />
                        <div className="invalid-feedback">
                          Please provide a phone number.
                        </div>
                      </div>
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Sign Up
                      </button>
                      <div>
                        <span className="text-danger">{signUpError}</span>
                      </div>
                    </form>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(
  state => {
    const { username } = state.user
    return { username }
  },
  { signIn: logUserIn, signUp: signUserUp }
)(SignInSlider)
