import axios from 'axios'
import { getApiUrl, getAuthHeader } from './service-helper'

export const createPayment = (order_id, source, lockercode, lockernumber) =>
  // const price = Math.trunc(dollar_amount * 100)

  axios.post(
    `${getApiUrl('orders')}/${order_id}/courier/payments`,
    { order_id, source, locker_code: lockercode, locker_number: lockernumber },
    { headers: getAuthHeader() }
  )
