import React from 'react'
import Breadcrumb from '../layout/breadcrumb'

export default ({ product }) => {
  console.log('product information in the product simple page: ', product)
  return (
    <React.Fragment>
      <Breadcrumb
        breadcrumbItemMatrix={[
          { name: product.category, link: '#', isActive: false },
          { name: product.product, link: '#', isActive: true }
        ]}
      />
      <h1 className="page-title pt-1">{product.product}</h1>
      <h2 className="h1 text-primary font-weight-light pt-3 pb-2">
        ${product.price}
      </h2>
    </React.Fragment>
  )
}

/* { name: product.subcategory, link: '#', isActive: false }, */
