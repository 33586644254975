import React, { useState } from 'react'
import { connect } from 'react-redux'

import LeftPane from '../../components/checkout/left-pane'
import RightPane from '../../components/checkout/right-pane'
import CheckoutLayout from '../../components/checkout/layout'
import OrderSummary from '../../components/checkout/order-summary'

import { updateForm } from '../../actions/form'
import validate from '../../util/validation/validateCheckoutDetails'
import { keyToTestName } from 'jest-snapshot/build/utils'

const Details = ({ form, dispatchField, history }) => {
  const [errors, setErrors] = useState([])
  const handleDispatchField = key => e => {
    console.log(e)
    dispatchField(key, e.target.value)
  }

  const [billingVisible, setBillingVisible] = useState(false)

  return (
    <CheckoutLayout currentPage="Your Details">
      <div className="container pb-5 mb-sm-4 mt-n2 mt-md-n3">
        <div className="row pt-4 mt-2">
          <LeftPane className="col-xl-9 col-md-8">
            {/* <div className="d-sm-flex justify-content-between bg-secondary px-4 py-3 mb-4">
              <div className="media">
                <img
                  className="img-thumbnail rounded-circle mr-3"
                  src="/img/customer.jpg"
                  width="95"
                  alt="Daniel Adams"
                />
                <div className="media-body align-self-center">
                  <h6 className="mb-1">Daniel Adams</h6>
                  <div className="font-size-sm">
                    <span className="text-warning">d.adams@example.com</span>
                  </div>
                  <div className="font-size-sm opacity-75">
                    <span className="mr-1">Reward points:</span>
                    <span className="font-weight-semibold">586</span>
                  </div>
                </div>
              </div>
              <div className="pt-3 pl-sm-2 text-right">
                <a className="btn btn-light btn-sm" href="account-profile.html">
                  <i className="icon ion-md-create mr-2" />
                  Edit profile
                </a>
              </div>
            </div> */}
            <h6 className="mb-3 pt-2 pb-3 border-bottom">Delivery address</h6>
            {errors.length > 0 && (
              <>
                <div className="small text-danger">
                  {errors[0].map(error => (
                    <div>*{error}</div>
                  ))}
                </div>
                <br />
              </>
            )}
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-fn">First Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="checkout-fn"
                    value={form.deliveryFirstName}
                    onChange={handleDispatchField('deliveryFirstName')}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-ln">Last Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="checkout-ln"
                    value={form.deliveryLastName}
                    onChange={handleDispatchField('deliveryLastName')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-email">E-mail Address</label>
                  <input
                    className="form-control"
                    type="email"
                    id="checkout-email"
                    value={form.deliveryEmail}
                    onChange={handleDispatchField('deliveryEmail')}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-phone">Phone Number</label>
                  <input
                    className="form-control"
                    type="text"
                    id="checkout-phone"
                    value={form.deliveryPhone}
                    onChange={handleDispatchField('deliveryPhone')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-city">City</label>
                  <input
                    className="form-control custom-select"
                    id="checkout-city"
                    style={{ background: 'none' }}
                    value={form.deliveryCity}
                    onChange={handleDispatchField('deliveryCity')}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-zip">ZIP Code</label>
                  <input
                    className="form-control"
                    type="text"
                    id="checkout-zip"
                    value={form.deliveryZip}
                    onChange={handleDispatchField('deliveryZip')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-address-1">Address 1</label>
                  <input
                    className="form-control"
                    type="text"
                    id="checkout-address-1"
                    value={form.deliveryAddress1}
                    onChange={handleDispatchField('deliveryAddress1')}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-address-2">Address 2</label>
                  <input
                    className="form-control"
                    type="text"
                    id="checkout-address-2"
                    value={form.deliveryAddress2}
                    onChange={handleDispatchField('deliveryAddress2')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-company">Company</label>
                  <input
                    className="form-control"
                    type="text"
                    id="checkout-company"
                    value={form.deliveryCompany}
                    onChange={handleDispatchField('deliveryCompany')}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="checkout-poNumber-1">PO #</label>
                  <input
                    className="form-control"
                    type="text"
                    id="checkout-poNumber-1"
                    value={form.deliveryPo}
                    onChange={handleDispatchField('deliveryPo')}
                  />
                </div>
              </div>
            </div>
            <h6 className="mb-3 pt-4 pb-3 border-bottom">Billing adress</h6>
            <div className="custom-control custom-checkbox">
              <input
                className="custom-control-input"
                type="checkbox"
                defaultChecked
                id="same-address"
                onClick={() => {
                  form.billingEnabled = !billingVisible
                  setBillingVisible(!billingVisible)
                }}
              />
              <label className="custom-control-label" htmlFor="same-address">
                Same as delivery address
              </label>
            </div>
            {billingVisible ? (
              <div>
                {errors.length > 0 && (
                  <>
                    {billingVisible ? (
                      <>
                        <div className="small text-danger">
                          {errors[1].map(error => (
                            <div>*{error}</div>
                          ))}
                        </div>
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="checkout-fn">First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        id="checkout-fn"
                        value={form.billingFirstName}
                        onChange={handleDispatchField('billingFirstName')}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="checkout-ln">Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        id="checkout-ln"
                        value={form.billingLastName}
                        onChange={handleDispatchField('billingLastName')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="checkout-email">E-mail Address</label>
                      <input
                        className="form-control"
                        type="email"
                        id="checkout-email"
                        value={form.billingEmail}
                        onChange={handleDispatchField('billingEmail')}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="checkout-phone">Phone Number</label>
                      <input
                        className="form-control"
                        type="text"
                        id="checkout-phone"
                        value={form.billingPhone}
                        onChange={handleDispatchField('billingPhone')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="checkout-city">City</label>
                      <input
                        className="form-control custom-select"
                        id="checkout-city"
                        style={{ background: 'none' }}
                        value={form.billingCity}
                        onChange={handleDispatchField('billingCity')}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="checkout-zip">ZIP Code</label>
                      <input
                        className="form-control"
                        type="text"
                        id="checkout-zip"
                        value={form.billingZip}
                        onChange={handleDispatchField('billingZip')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="checkout-address-1">Address 1</label>
                      <input
                        className="form-control"
                        type="text"
                        id="checkout-address-1"
                        value={form.billingAddress1}
                        onChange={handleDispatchField('billingAddress1')}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="checkout-address-2">Address 2</label>
                      <input
                        className="form-control"
                        type="text"
                        id="checkout-address-2"
                        value={form.billingAddress2}
                        onChange={handleDispatchField('billingAddress2')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="checkout-company">Company</label>
                      <input
                        className="form-control"
                        type="text"
                        id="checkout-company"
                        value={form.billingCompany}
                        onChange={handleDispatchField('billingCompany')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="d-flex pt-4 mt-3">
              <div className="w-50 pr-3">
                <a
                  className="btn btn-outline-secondary btn-block"
                  href="/checkout/cart"
                >
                  <i className="icon ion-md-arrow-dropleft mr-2" />
                  <span className="d-none d-sm-inline">Back to cart</span>
                  <span className="d-inline d-sm-none">Back</span>
                </a>
              </div>
              <div className="w-50 pl-2">
                <a
                  className="btn btn-primary btn-block"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    validate(form)
                      .then(x => history.push('/checkout/delivery'))
                      .catch(errors => {
                        setErrors(errors)
                      })
                  }}
                >
                  <span className="d-none d-sm-inline">
                    Proceed to delivery
                  </span>
                  <span className="d-inline d-sm-none">Next</span>
                  <i className="icon ion-md-arrow-dropright ml-2" />
                </a>
              </div>
            </div>
          </LeftPane>

          <RightPane style={{ paddingRight: '0px' }}>
            <OrderSummary />
          </RightPane>
        </div>
      </div>
    </CheckoutLayout>
  )
}

export default connect(
  state => ({ form: state.form }),
  { dispatchField: updateForm }
)(Details)
