import React from 'react'

import Icon from '../ioicon'

export default ({ product }) => {
  const {
    sku,
    color,
    weight_units,
    weight,
    dimension,
    coverage,
    coverage_units,
    description
  } = product
  return (
    <div className="accordion" id="productPanels">
      <div className="card">
        <div className="card-header">
          <h3 className="accordion-heading">
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls="productInfo"
              style={{ cursor: 'default' }}
            >
              <span className="d-inline-block pr-2 border-right mr-2 align-middle mt-n1">
                <i
                  data-feather="info"
                  style={{ width: '1.1rem', height: '1.1rem' }}
                />
              </span>
              Product information
              {/* <span className="accordion-indicator">
                <Icon name="arrow-dropup" style={{ fontSize: '1.4em' }} />
              </span> */}
            </a>
          </h3>
        </div>
        <div
          className="collapse show"
          id="productInfo"
          data-parent="#productPanels"
        >
          <div className="card-body">
            <ul className="mb-0" style={{ listStyle: 'none' }}>
              <li style={{ marginBottom: '5px' }}>
                <b>SKU: </b>
                {sku}
              </li>
              <li style={{ marginBottom: '5px' }}>
                <b>Description: </b>
                {description}
              </li>
              {color ? (
                <li style={{ marginBottom: '5px' }}>
                  <b>Color: </b>
                  {color}
                </li>
              ) : (
                ''
              )}
              {weight_units ? (
                <li style={{ marginBottom: '5px' }}>
                  <b>Weight({weight_units || 'lbs'}): </b> {weight}
                </li>
              ) : (
                ''
              )}
              {dimension ? (
                <li style={{ marginBottom: '5px' }}>
                  <b>Dimension: </b>
                  {dimension}
                </li>
              ) : (
                ''
              )}
              {coverage ? (
                <li style={{ marginBottom: '5px' }}>
                  <b>Coverage: </b>
                  {coverage} {coverage_units}
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
