import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { cartCostSelector } from '../../selectors/cartCostSelector'

const OrderSummary = ({ cost, delivery }) => {
  const taxes = 0.0831 * cost
  const discount = 0

  return (
    <>
      <h2 className="h6 px-4 py-3 bg-secondary text-center">Order summary</h2>
      <div className="font-size-sm border-bottom pt-2 pb-3">
        <div className="d-flex justify-content-between mb-2">
          <span>Subtotal:</span>
          <span>${cost.toFixed(2)}</span>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <span>Delivery:</span>
          <span>${delivery}</span>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <span>Taxes:</span>
          <span>${taxes.toFixed(2)}</span>
        </div>
        {/* <div className="d-flex justify-content-between">
          <span>Discount:</span>
          <span>-${discount.toFixed(2)}</span>
        </div> */}
      </div>
      <div className="h3 font-weight-semibold text-center py-3">
        ${(cost + delivery + taxes - discount).toFixed(2)}
      </div>
    </>
  )
}

OrderSummary.propTypes = {
  cost: PropTypes.number
}

export default connect(state => ({ cost: +cartCostSelector(state) }))(
  OrderSummary
)
