import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { API_URL } from '../../config'

import Shop from '../../pages/shop'
import Product from '../../pages/product'
import Categories from '../../pages/categories'
import Cart from '../../pages/checkout/cart'
import NotFound from '../../pages/404'

import Delivery from '../../pages/checkout/delivery'
import Details from '../../pages/checkout/details'
import Payment from '../../pages/checkout/payment'
import Complete from '../../pages/checkout/complete'
import Review from '../../pages/checkout/review'
import OrderTracking from '../../pages/tracking/order-tracking'
import OrderHistory from '../../pages/order-history'
import Search from '../../pages/search'

const Router = ({ username }) => {
  //Should refactor this later to pass username through context
  console.log('username in router: ', username)

  return (
    <Switch>
      <Route exact path="/" component={Shop} />
      <Route
        exact
        path="/"
        render={props => <Shop {...props} username={username} />}
      />
      <Route path="/checkout/cart" component={Cart} />
      <Route path="/checkout/details" component={Details} />
      <Route path="/checkout/delivery" component={Delivery} />
      <Route path="/checkout/payment" component={Payment} />
      <Route path="/checkout/review" component={Review} />
      <Route path="/checkout/complete/:id" component={Complete} />
      <Route path="/order-tracking/:id" component={OrderTracking} />
      <Route exact path="/shop" component={Shop} />
      <Route path="/product/:id" render={props => <Product {...props} />} />
      <Route path="/categories" component={Categories} />
      <Route path="/order-history" component={OrderHistory} />
      <Route path="/search" component={Search} />
      <Route
        path="/confirm-email/:token"
        component={props => {
          window.location.href = `${API_URL}sign_up/confirm_email/${props.match.params.token}`
          return null
        }}
      ></Route>

      <Route component={NotFound} />
    </Switch>
  )
}

export default connect(store => {
  const { username } = store.user
  return { username }
})(Router)

// orderID
// courier=1
// delivery cost
