import React from 'react'

import Breadcrumb from './breadcrumb'
import Title from './titleSubTitle'

export default ({ children, subTitle, breadCrumbs }) => {
  return (
    <div className="page-title-wrapper" aria-label="Page title">
      <div className="container">
        <Breadcrumb
          breadcrumbItemMatrix={breadCrumbs}
        />
        <Title subTitle={subTitle}>{children}</Title>
      </div>
    </div>
  )
}
 