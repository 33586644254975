import React, { useState } from 'react'

export default ({ filteredProducts, setFilteredProducts }) => {
  const [sortBy, useSortBy] = useState('')

  const setSort = value => {
    if (value === 'low') {
      console.log('filtered prods pre sort: ', filteredProducts)
      const newFilters = filteredProducts.sort((a, b) => a.price - b.price)
      // return parseInt(b.price) - parseInt(a.price)
      console.log('filtered prods after sort: ', newFilters)
      setFilteredProducts([...newFilters])
    } else if (value === 'high') {
      const newFilters = filteredProducts.sort((a, b) => b.price - a.price)
      // return parseInt(b.price) - parseInt(a.price)
      console.log('filtered prods after sort: ', newFilters)
      setFilteredProducts([...newFilters])
    } else if (value === 'alpha') {
      const newFilters = filteredProducts.sort((a, b) => {
        if (a.product < b.product) {
          return -1
        }
        if (a.product > b.product) {
          return 1
        }
        return 0
      })
      // return parseInt(b.price) - parseInt(a.price)
      console.log('filtered prods after sort: ', newFilters)
      setFilteredProducts([...newFilters])
    } else if (value === 'revAlpha') {
      const newFilters = filteredProducts.sort((a, b) => {
        if (b.product < a.product) {
          return -1
        }
        if (b.product > a.product) {
          return 1
        }
        return 0
      })
      console.log('filtered prods after sort: ', newFilters)
      setFilteredProducts([...newFilters])
    }

    // if (value === 'low') {
    //   setFilteredProducts(
    //     filteredProducts.sort((a, b) => {
    //       return a.price - b.price
    //     })
    //   )
    //   console.log('sorted by price')
    // }
  }

  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-sm-between pb-3">
      <div className="d-flex flex-wrap">
        <div className="form-inline flex-nowrap mr-3 mr-sm-4 pb-3">
          <label
            className="text-nowrap mr-2 d-none d-sm-block"
            htmlFor="sorting"
          >
            Sort by:
          </label>
          <select
            className="form-control "
            id="sorting"
            value={sortBy}
            onChange={e => {
              console.log(e.target.value)
              useSortBy(e.target.value)
              setSort(e.target.value)
            }}
          >
            <option default value="">
              Popular
            </option>
            <option value="low">Low - High Price</option>
            <option value="high">High - Low Price</option>
            <option value="alpha">A - Z Order</option>
            <option value="revAlpha">Z - A Order</option>
          </select>
        </div>
        {/* Disabling for now, we can set this up later if we want
        <div className="form-inline flex-nowrap pb-3">
          <label className="mr-2 d-none d-sm-block" htmlFor="number">
            Show:
          </label>
          <select className="form-control  mr-sm-2" id="number">
            <option>12</option>
            <option>24</option>
            <option>48</option>
            <option>96</option>
          </select>
          <small className="form-text text-muted text-nowrap d-none d-sm-block">
            from 860
          </small>
        </div> */}
      </div>
      {/* <div className="form-inline d-none d-md-flex flex-nowrap pb-3">
        <label className="mr-2" htmlFor="pager">
          Page:
        </label>
        <input
          className="form-control mr-2"
          type="number"
          id="pager"
          defaultValue="2"
          style={{ width: '4rem' }}
        />
        <span className="form-text">/ 30</span>
      </div> */}
    </div>
  )
}
