import React from 'react'
import { connect } from 'react-redux'

import { addToCart } from '../../actions/cart'

const AddToCart = ({ product, addProductToCart }) => {
  const handleAddToCart = product => () => {
    console.log('product in add to cart: ', product)
    addProductToCart(product)
  }
  return (
    <React.Fragment>
      <div>
        <input
          className="px-2 form-control mr-2"
          type="number"
          name="quantity"
          style={{ width: '3.2rem' }}
          defaultValue="1"
          required
        />
      </div>
      <div>
        <button
          className="btn btn-primary px-5 mr-2"
          type="submit"
          onClick={handleAddToCart(product)}
        >
          <i className="icon ion-md-cart" />
          &nbsp;Add to cart
        </button>
      </div>
      {/* <button className="btn btn-link box-shadow-0 nav-link-inline my-2">
        <i
          className="align-middle mr-1"
          data-feather="heart"
          style={{ width: '1.1rem', height: '1.1rem' }}
        />
        <i className="icon ion-md-heart-empty" />
        &nbsp;Add to favorites
      </button> */}
    </React.Fragment>
  )
}

export default connect(
  null,
  { addProductToCart: addToCart }
)(AddToCart)
