import { combineReducers } from 'redux'

import user from './user'
import product from './product'
import cart from './cart'
import form from './form'

export const rootReducer = combineReducers({
  user,
  product,
  cart,
  form
})
