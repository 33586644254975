import React, { useState, useEffect, useRef } from 'react'

import Layout from '../../components/layout/layout'
import Breadcrumb from '../../components/layout/breadcrumb'

import Fetcher from '../../components/fetcher'
import { getOrderStatus } from '../../services/order-tracking'

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default ({ match }) => {
  const chosenOrder = match.params.id
  let [count, setCount] = useState(0)

  //Updates once per minute - tracking this with a counter for now. Later we should setup a socket here if users are going to stay on this page.

  useInterval(() => {
    setCount(count + 1)
  }, 15000)

  return (
    <Layout courier="1">
      <Fetcher service={getOrderStatus} args={[chosenOrder]}>
        {({ data }) => {
          const orderStatus = data.order_status
          const tookanEmbedUrl = data.tracking_url || ''

          {
            /* Potential Statuses:
            "Placed",
            "Accepted",
            "No Shopper Available",
            "Rejected",
            "Shopping Completed",
            "Awaiting Pickup",
            "Pickup Failed",
            "Pickup Completed",
            "In Transit",
            "Delivery Failed",
            "Completed" */
          }
          console.log(count)
          let progressValue = 0
          let activeStep = 0

          const setStatus = () => {
            if (
              orderStatus === 'Accepted' ||
              orderStatus === 'Placed' ||
              orderStatus === 'No Shopper Available' ||
              orderStatus === 'Rejected'
            ) {
              progressValue = 20
              activeStep = 1
            } else if (orderStatus === 'Shopping In Progress') {
              progressValue = 40
              activeStep = 2
            } else if (
              orderStatus === 'Awaiting Pickup' ||
              orderStatus === 'Shopping Completed'
            ) {
              progressValue = 60
              activeStep = 3
            } else if (
              orderStatus === 'In Transit' ||
              orderStatus === 'Delivery Failed'
            ) {
              progressValue = 80
              activeStep = 4
            } else if (orderStatus === 'Completed') {
              progressValue = 100
              activeStep = 5
            }
          }

          setStatus()

          return (
            <>
              <div className="page-title-wrapper" aria-label="Page title">
                <div className="container">
                  <Breadcrumb
                    breadcrumbItemMatrix={[
                      { name: 'Order Tracking', link: '#', isActive: false }
                    ]}
                  />
                  <h1 className="page-title">
                    Tracking order:{' '}
                    <span className="h3 font-weight-semibold">
                      #0000{chosenOrder}
                    </span>
                  </h1>
                  <span className="d-block mt-2 text-muted" />
                  <hr className="mt-4" />
                </div>
              </div>
              <div className="container pb-5 mb-sm-4">
                <div className="container pb-5 mb-sm-4 mt-n2 mt-md-n3">
                  <div className="steps">
                    <div className="steps-header">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: progressValue + '%' }}
                          aria-valuenow={`${progressValue}`}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                    <div className="steps-body">
                      {/* <div
                        className={
                          'step ' +
                          (activeStep === 0 ? 'step-active' : '') +
                          (activeStep > 0 ? 'step-completed' : '')
                        }
                      >
                        {activeStep > 0 ? (
                          <span className="step-indicator">
                            <i data-feather="check" />
                            <i className="icon ion-md-checkmark" />
                          </span>
                        ) : (
                          ''
                        )}
                        <span className="step-icon">
                          <i className="icon ion-md-checkmark-circle-outline mr-2" />
                        </span>
                        Order Accepted
                      </div>
                      <div
                        className={
                          'step ' +
                          (activeStep === 1 ? 'step-active' : '') +
                          (activeStep > 1 ? 'step-completed' : '')
                        }
                      >
                        {activeStep > 1 ? (
                          <span className="step-indicator">
                            <i data-feather="check" />
                            <i className="icon ion-md-checkmark" />
                          </span>
                        ) : (
                          ''
                        )}
                        <span className="step-icon">
                          <i className="icon ion-md-settings mr-2" />
                        </span>
                        Shopping In Progress
                      </div> */}
                      <div
                        className={
                          'step ' +
                          (activeStep === 2 ? 'step-active' : '') +
                          (activeStep > 2 ? 'step-completed' : '')
                        }
                      >
                        {activeStep > 2 ? (
                          <span className="step-indicator">
                            <i data-feather="check" />
                            <i className="icon ion-md-checkmark" />
                          </span>
                        ) : (
                          ''
                        )}
                        <span className="step-icon">
                          <i data-feather="award" />
                          <i className="icon ion-md-ribbon mr-2" />
                        </span>
                        Awaiting Driver
                      </div>
                      <div
                        className={
                          'step ' +
                          (activeStep === 3 ? 'step-active' : '') +
                          (activeStep > 3 ? 'step-completed' : '')
                        }
                      >
                        {activeStep > 3 ? (
                          <span className="step-indicator">
                            <i data-feather="check" />
                            <i className="icon ion-md-checkmark" />
                          </span>
                        ) : (
                          ''
                        )}
                        <span className="step-icon">
                          <i data-feather="truck" />
                          <i className="icon ion-md-car mr-2" />
                        </span>
                        In Transit
                      </div>
                      <div
                        className={
                          'step ' +
                          (activeStep === 4 ? 'step-active' : '') +
                          (activeStep > 4 ? 'step-completed' : '')
                        }
                      >
                        {activeStep > 4 ? (
                          <span className="step-indicator">
                            <i data-feather="check" />
                            <i className="icon ion-md-checkmark" />
                          </span>
                        ) : (
                          ''
                        )}
                        <span className="step-icon">
                          <i data-feather="home" />
                          <i className="icon ion-md-home mr-2" />
                        </span>
                        Delivered
                      </div>
                    </div>
                  </div>
                  {tookanEmbedUrl ? (
                    <div className="mt-4">
                      <iframe
                        id="tookan-iframe"
                        title="Order Tracking"
                        width="600"
                        height="600"
                        src={tookanEmbedUrl}
                      />
                    </div>
                  ) : (
                    <div className="mt-4 text-center">
                      <h3>Delivery Map Will Appear When In Transit</h3>
                    </div>
                  )}
                  <div className="d-sm-flex flex-wrap justify-content-between align-items-center text-center pt-4">
                    <div className="custom-control custom-checkbox mt-2 mr-3">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="notify-me"
                        defaultChecked
                        style={{ background: '#81adc1' }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="notify-me"
                      >
                        Notify me when order is delivered
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }}
      </Fetcher>
    </Layout>
  )
}
