import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import { Accordion, Card, useAccordionToggle } from 'react-bootstrap'

// import Icon from '../../ioicon'

// const CustomToggle = ({ children, eventKey }) => {
//   // const [dropDownOpen, setDropDownOpen] = useState(false)

//   // const toggleOpen = useAccordionToggle(eventKey, () =>
//   //   setDropDownOpen(!dropDownOpen)
//   // )

//   return (
//     <Link
//       to="/"
//       style={{ border: 'none', color: '#000', textDecoration: 'none' }}
//     >
//       {/* {dropDownOpen ? (
//         <Icon name="arrow-dropdown" className="mr-2" />
//       ) : (
//         <Icon name="arrow-dropright" className="mr-2" />
//       )}
//       {children} */}
//     </Link>
//   )
// }

export default ({ products, filteredProducts, setFilteredProducts }) => {
  const handleFilter = clickedCategory => {
    const productsToFilter = products.filter(product => {
      return product.category === clickedCategory
    })

    setFilteredProducts(productsToFilter)
  }

  const categories = {}

  if (products.length) {
    products.forEach(product => {
      if (categories[product.category]) {
        categories[product.category]++
      } else {
        categories[product.category] = 1
      }
      //     if (
      //       categories[product.category].subcategories.filter(subcat => {
      //         return subcat.name === product.subcategory
      //       }).length
      //     ) {
      //       const subcat = categories[product.category].subcategories.filter(
      //         subcategory => subcategory.name === product.subcategory
      //       )
      //       subcat[0].count++
      //     } else {
      //       categories[product.category].subcategories.push({
      //         name: product.subcategory,
      //         count: 1
      //       })
      //     }
      //   } else {
      //     categories[product.category] = {
      //       count: 1,
      //       subcategories: [
      //         {
      //           name: product.subcategory,
      //           count: 1
      //         }
      //       ]
      //     }
      //   }
      // })
    })
  }

  console.log('categories ', categories)

  return (
    <>
      <h3 className="widget-title">Shop categories</h3>
      <ul>
        {Object.keys(categories).map((category, i) => {
          return (
            <li>
              <Link
                to="/"
                style={{
                  border: 'none',
                  color: '#000',
                  textDecoration: 'none'
                }}
                onClick={() => {
                  handleFilter(category)
                }}
              >
                {' '}
                {category}
                <span className="badge text-muted ml-1">
                  ({categories[category]})
                </span>
                {/* {dropDownOpen ? (
            <Icon name="arrow-dropdown" className="mr-2" />
          ) : (
            <Icon name="arrow-dropright" className="mr-2" />
          )}
          {children} */}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

{
  /* // return (
//   <Accordion key={i}>
//     <Card className="has-children" style={{ border: 'none' }}>
//       <Card.Header style={{ border: 'none' }}>
//         <CustomToggle eventKey="0">
//           {category}
//           <span className="badge text-muted ml-1">
//             ({categories[category].count})
//           </span>
//         </CustomToggle>
//       </Card.Header>
//       <Accordion.Collapse eventKey="0" style={{ paddingLeft: '20px' }}>
//         <ul className="collapse show" id={`#${category}`}>
//           {categories[category].subcategories.map(subcategory => {
//             return (
//               <li
//                 key={subcategory.name}
//                 onClick={() => {
//                   handleFilter(subcategory.name, category)
//                   console.log('the sub cat', subcategory)
//                 }}
//               >
//                 {subcategory.name}
//                 <span className="badge text-muted ml-1">
//                   ({subcategory.count})
//                 </span>
//               </li>
//             )
//           })}
//         </ul>
//       </Accordion.Collapse>
//     </Card>
//   </Accordion> 
// )


  /* <React.Fragment>
      <h3 className="widget-title">Shop categories</h3>
      <ul id="shopCategories">
        <li className="has-children">
          <Link to="#shoes" data-toggle="collapse" aria-expanded="false">
            <Icon name="arrow-dropdown" className="mr-2" />
            Caps, Jacks &amp; Collars
            <span className="badge text-muted ml-1">(1138)</span>
          </Link>
          <ul
            className="collapse show"
            id="shoes"
            data-parent="#shopCategories"
          >
            <li>
              <Link to="/">
                Roof Jacks
                <span className="badge text-muted ml-1">(508)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                Storm Collars
                <span className="badge text-muted ml-1">(423)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                Caps
                <span className="badge text-muted ml-1">(97)</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="has-children">
          <a className="collapsed" href="#clothing" data-toggle="collapse">
            <Icon name="arrow-dropdown" className="mr-2" />
            Ice &amp; Water
            <span className="badge text-muted ml-1">(10)</span>
          </a>
          <ul className="collapse" id="clothing" data-parent="#shopCategories">
            <li>
              <Link to="/">
                Granulated
                <span className="badge text-muted ml-1">(1)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                High Temp
                <span className="badge text-muted ml-1">(1)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                Smooth
                <span className="badge text-muted ml-1">(1)</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="has-children">
          <a className="collapsed" href="#bags" data-toggle="collapse">
            <Icon name="arrow-dropdown" className="mr-2" />
            Paints &amp; Caulking
            <span className="badge text-muted ml-1">(420)</span>
          </a>
          <ul className="collapse" id="bags" data-parent="#shopCategories">
            <li>
              <Link to="/">
                Paint
                <span className="badge text-muted ml-1">(180)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                Silicone
                <span className="badge text-muted ml-1">(132)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                Caulking
                <span className="badge text-muted ml-1">(47)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                Cement
                <span className="badge text-muted ml-1">(61)</span>
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <a className="collapsed" href="#accessories" data-toggle="collapse">
            <Icon name="arrow-dropdown" className="mr-2" />
            Fasteners
            <span className="badge text-muted ml-1">(874)</span>
          </a>
        </li>
        <li className="has-children">
          <a className="collapsed" href="#hipridge" data-toggle="collapse">
            <Icon name="arrow-dropdown" className="mr-2" />
            Hip &amp; Ridge
            <span className="badge text-muted ml-1">(874)</span>
          </a>
          <ul className="collapse" id="hipridge" data-parent="#shopCategories">
            <li>
              <Link to="/">
                Impact Resistant
                <span className="badge text-muted ml-1">(211)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                High Profile
                <span className="badge text-muted ml-1">(195)</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="has-children">
          <a className="collapsed" href="#shingles" data-toggle="collapse">
            <Icon name="arrow-dropdown" className="mr-2" />
            Shingles
            <span className="badge text-muted ml-1">(30)</span>
          </a>
          <ul className="collapse" id="shingles" data-parent="#shopCategories">
            <li>
              <Link to="/">
                Impact Resistant
                <span className="badge text-muted ml-1">(211)</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="has-children">
          <a className="collapsed" href="#underlayments" data-toggle="collapse">
            <Icon name="arrow-dropdown" className="mr-2" />
            Underlayments
            <span className="badge text-muted ml-1">(20)</span>
          </a>
          <ul
            className="collapse"
            id="underlayments"
            data-parent="#shopCategories"
          >
            <li>
              <Link to="/">
                Synthetic
                <span className="badge text-muted ml-1">(4)</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="has-children">
          <a className="collapsed" href="#ventilation" data-toggle="collapse">
            <Icon name="arrow-dropdown" className="mr-2" />
            Ventilation
            <span className="badge text-muted ml-1">(45)</span>
          </a>
          <ul
            className="collapse"
            id="ventilation"
            data-parent="#shopCategories"
          >
            <li>
              <Link to="/">
                Static Vents
                <span className="badge text-muted ml-1">(211)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                Ridge Vents
                <span className="badge text-muted ml-1">(195)</span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </React.Fragment> */
}
