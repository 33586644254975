import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_QUANTITY } from '../actions/cart'

const run = (numTimesToRun, action) => {
  if (numTimesToRun !== 0) {
    action()
    return run(numTimesToRun - 1, action)
  }
}

const initialState = { items: [] }
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const { items } = state
      const product = action.payload
      console.log('product in reducer: ', product)
      return { ...state, items: [...items, product] }
    }
    case REMOVE_FROM_CART: {
      return {
        ...state,
        items: state.items.filter(x => x.sku !== action.payload)
      }
    }

    case UPDATE_QUANTITY: {
      const { sku, quantity } = action.payload
      const productToClone = state.items.find(x => x.sku === sku)
      const arrayOfItems = state.items.filter(x => x.sku !== sku)

      run(quantity, () => {
        arrayOfItems.push({ ...productToClone })
      })
      return { ...state, items: [...arrayOfItems] }
    }
    default:
      return state
  }
}
