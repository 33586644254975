import React, { useState } from 'react'
import { Range } from 'react-range'

export default ({ filteredProducts, setFilteredProducts }) => {
  const [values, setValues] = useState([0, 500])

  const handleFilterClick = e => {
    console.log('filtered products', filteredProducts)
    e.preventDefault()

    const priceFilter = filteredProducts.filter(item => {
      return (
        parseInt(item.price) >= values[0] && parseInt(item.price) <= values[1]
      )
    })
    console.log('priceFilter: ', priceFilter)
  }

  return (
    <>
      <h3 className="widget-title">Price range</h3>
      <form>
        <Range
          step={1}
          min={0}
          max={500}
          values={values}
          onChange={values => setValues(values)}
          onChangeComplete={values => console.log(values)}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '3px',
                width: '100%',
                backgroundColor: '#e7e7e7'
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <>
              <div
                {...props}
                style={{
                  ...props.style,
                  width: '.725rem',
                  height: '.725rem',
                  border: '0',
                  boxShadow: 'none',
                  borderRadius: '50%',
                  background: '#5c77fc'
                }}
              ></div>
            </>
          )}
        />
        <footer className="ui-range-slider-footer">
          <div className="column">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={handleFilterClick}
            >
              Filter
            </button>
          </div>
          <div className="column">
            <div className="ui-range-values">
              <div className="ui-range-label">Price: &nbsp;</div>
              <div className="ui-range-value-min">
                $<span>{values[0]}</span>
              </div>
              &nbsp;–&nbsp;
              <div className="ui-range-value-max">
                $<span>{values[1]}</span>
              </div>
            </div>
          </div>
        </footer>
      </form>
      {/* <form
        className="range-slider"
        data-start-min="250"
        data-start-max="650"
        data-min="0"
        data-max="1000"
        data-step="1"
      >
        <div className="ui-range-slider noUi-target noUi-ltr noUi-horizontal">
          <div className="noUi-base">
            <div className="noUi-connects">
              <div
                className="noUi-connect"
                style={{ transform: 'translate(25%, 0px) scale(0.4, 1)' }}
              />
            </div>
            <div
              className="noUi-origin"
              style={{
                transform: 'transform: translate(-75%, 0px); z-index: 5'
              }}
            >
              
            </div>
            <div
              className="noUi-origin"
              style={{ transform: 'translate(-35%, 0px); z-index: 4' }}
            >
              <div
                className="noUi-handle noUi-handle-upper"
                data-handle="1"
                tabIndex="0"
                role="slider"
                aria-orientation="horizontal"
                aria-valuemin="250.0"
                aria-valuemax="1000.0"
                aria-valuenow="650.0"
                aria-valuetext="650.00"
              />
            </div>
          </div>
        </div>
        
      </form> */}
    </>
  )
}
