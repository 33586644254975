import { createSelector } from 'reselect'
import _ from 'lodash'

const cartSelector = state => state.cart.items

export const productItemsWithQuantity = createSelector(
  [cartSelector],
  items => {
    return Object.values(
      _.map(_.groupBy(items, 'sku'), item => ({
        ...item[0],
        quantity: item.length
      }))
    )
  }
)
