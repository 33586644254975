import React from 'react'

export default () => {
  return (
    <div className="container pb-5 mt-5" style={{ height: '50vh' }}>
      <div className="pt-3 pb-2 text-center">
        <h1 className="h2">Page not found!</h1>
        <p className="text-muted" style={{ lineHeight: '2.5' }}>
          If you think you've reached this page in error, try signing in or
          reach out to us at{' '}
          <a
            className="font-weight-semibold"
            href="mailto://support@materialdrop.com"
          >
            support@materialdrop.com
          </a>
        </p>
      </div>
    </div>
  )
}
