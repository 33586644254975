import React from 'react'
export default ({ children, className, ...props }) => {
  return (
    <h2
      {...props}
      className={`h6 px-4 py-3 bg-secondary mb-4 ${className}`}
    >
      {children}
    </h2>
  )
}
