import axios from 'axios'
import { getApiUrl, getAuthHeader } from './service-helper'

export const createOrder = (
  user_id,
  description,
  delivery_time,
  delivery_address,
  city,
  state,
  country,
  timezone,
  facility_id,
  product_item_ids,
  delivery_phone,
  po,
  lockercode,
  lockernumber,
  pickupaddress,
  cost
) =>
  axios.post(
    getApiUrl('orders'),
    {
      user_id,
      description,
      delivery_time,
      delivery_address,
      city,
      state,
      country,
      timezone,
      facility_id,
      product_item_ids,
      delivery_phone,
      purchase_order_number: po,
      locker_code: lockercode,
      locker_number: lockernumber,
      pickup_address: pickupaddress,
      price: Math.round(cost * 100)
    },
    { headers: getAuthHeader() }
  )
