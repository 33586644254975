import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { StripeProvider, Elements } from 'react-stripe-elements'

import createStore from './reducers/createStore'
import Router from './components/routing/router'
import ScrollToTop from './components/layout/scrollToTop'
import { StripeApiKey } from './config'

function App() {
  const { store, persistor } = createStore()
  return (
    <Provider store={store}>
      <StripeProvider apiKey={StripeApiKey}>
        <Elements>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Router>
                <ScrollToTop />
              </Router>
            </BrowserRouter>
          </PersistGate>
        </Elements>
      </StripeProvider>
    </Provider>
  )
}

export default App
