import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Elements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements'
import queryString from 'query-string'

import CheckoutLayout from '../../components/checkout/layout'
import Header from '../../components/checkout/header'
import OrderSummary from '../../components/checkout/order-summary'
import PromoCode from '../../components/checkout/promo-code'
import Icon from '../../components/ioicon'

import { updateForm } from '../../actions/form'

import { logUserInGuest } from '../../actions/user'

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '20px',
        color: '#424770',
        // border: '1px solid black',
        backgroundColor: '#f7f7f7',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#c23d4b'
      }
    }
  }
}

const Payment = ({ dispatchField, stripe, history, location, login }) => {
  const query = queryString.parse(location.search)
  const {
    courier,
    facility,
    address,
    po,
    name,
    phone,
    lockercode,
    lockernumber,
    email,
    cost,
    pickupaddress
  } = query

  const [gettingToken, setGettingToken] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const getToken = async () => {
    try {
      setGettingToken(true)
      const { token } = await stripe.createToken({ name })
      // if (!token) return
      // OK how to change - refactor this flow so when this page loads it clears the token - token should be dispatched as null when the page loads
      // Login should be pulled out of this and done separately in a try/catch block above getToken
      console.log('token', token)
      console.log('email in payment: ', email)
      dispatchField('token', token.id)
      dispatchField('last4', token.card.last4)
      await login(email, phone, name)
      if (courier === '1') {
        setTimeout(
          () =>
            history.push(
              `/checkout/review?courier=1&cost=${cost}&facility=${facility}&address=${address}&po=${po}&name=${name}&phone=${phone}&lockercode=${lockercode}&lockernumber=${lockernumber}&email=${email}&pickupaddress=${pickupaddress}`
            ),
          1750
        )
      } else {
        history.push(`/checkout/review`)
      }
    } catch (e) {
      console.log('error getting token and creating user, ', e)
      setErrorMessage(e.message)
      setGettingToken(false)
    }
  }

  //pulls out the query to payment for
  // id=
  // courier=1
  // cost=

  return (
    <CheckoutLayout currentPage="Payment" courier={courier}>
      <div className="col-xl-9 col-md-8">
        <Header>Enter payment method</Header>
        <div className="accordion mb-4" id="payment-method" role="tablist">
          <div className="card">
            {/* <div className="card-header" role="tab">
              <h3 className="accordion-heading">
                <a href="#card" data-toggle="collapse">
                  <i
                    className="icon ion-md-card"
                    style={{ width: '1.25rem', height: '1.25rem' }}
                  />
                  &nbsp; Pay with Credit Card
                  <span className="accordion-indicator">
                    <i data-feather="chevron-up" />
                  </span>
                </a>
              </h3>
            </div> */}
            <div
              className="show"
              id="card"
              data-parent="#payment-method"
              role="tabpanel"
            >
              <div className="card-body">
                <p>
                  We accept following credit cards:&nbsp;&nbsp;
                  <img
                    className="d-inline-block align-middle"
                    src="/img/cards.png"
                    style={{ width: '187px' }}
                    alt="Credit Cards"
                  />
                </p>
                <div style={{ width: '420px', margin: '0 auto' }}>
                  {/* <img
                    src="/img/creditcard.png"
                    style={{ width: '80%', margin: '0 auto' }}
                    alt="Credit Cards"
                  /> */}
                </div>
                <br />
                <div
                  style={{
                    padding: '2px',
                    border: '1px solid rgba(0, 0, 0, 0.085)',
                    background: '#F7F7F7'
                  }}
                >
                  <CardElement {...createOptions()} />
                  {/* <div className="split-form">
                  <label>
                    Card number
                    <CardNumberElement {...createOptions()} />
                  </label>
                  <label>
                    Expiration date
                    <CardExpiryElement {...createOptions()} />
                  </label>
                </div>
                <div className="split-form">
                  <label>
                    CVC
                    <CardCVCElement {...createOptions()} />
                  </label>
                  <label>
                    Postal code
                    <input
                      name="name"
                      type="text"
                      placeholder="94115"
                      className="StripeElement"
                      required
                    />
                  </label>
                </div>
                {/* <div className="error" role="alert">
          {this.state.errorMessage}
        </div> */}
                </div>
              </div>
            </div>
          </div>
          {errorMessage ? (
            <div>
              <p style={{ color: 'red' }}>
                Something went wrong with your order, please double check your
                payment information and try again
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="d-flex pt-2">
          <div className="w-50 pr-3">
            <a
              className="btn btn-outline-secondary btn-block"
              href="https://www.materialdrop.com"
            >
              <Icon name="arrow-dropleft" className="mr-1" />
              Back
            </a>
          </div>
          <div className="w-50 pl-2">
            {gettingToken ? (
              <a
                className="btn btn-secondary btn-block"
                style={{ cursor: 'pointer' }}
                disabled
              >
                <span className="d-none d-sm-inline">Review your order</span>
              </a>
            ) : (
              <a
                className="btn btn-primary btn-block"
                style={{ cursor: 'pointer' }}
                onClick={() => getToken()}
              >
                <span className="d-none d-sm-inline">Review your order</span>
                <span className="d-inline d-sm-none">Next</span>
                <Icon name="arrow-dropright" className="ml-1" />
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-4 pt-4 mt-3 pt-md-0 mt-md-0">
        {courier === '1' ? (
          <OrderSummary delivery={parseFloat(cost)} />
        ) : (
          <OrderSummary />
        )}
        {/* <PromoCode /> */}
      </div>
    </CheckoutLayout>
  )
}

export default connect(null, {
  dispatchField: updateForm,
  login: logUserInGuest
})(injectStripe(Payment))
