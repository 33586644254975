import React from 'react'
import { connect } from 'react-redux'

import Layout from '../../components/layout/layout'
import LeftPane from '../../components/checkout/left-pane'
import RightPane from '../../components/checkout/right-pane'
import Breadcrumb from '../../components/layout/breadcrumb'
import Title from '../../components/layout/titleSubTitle'
import Header from '../../components/checkout/header'
import ShoppingCart from '../../components/checkout/shopping-cart'
import Icon from '../../components/ioicon'
import { productItemsWithQuantity } from '../../selectors/productQuantitySelector'
import { cartCostSelector } from '../../selectors/cartCostSelector'

const Cart = ({ items, cost }) => {
  console.log(items)
  return (
    <Layout>
      <div className="page-title-wrapper" aria-label="Page title">
        <div className="container">
          <Breadcrumb
            breadcrumbItemMatrix={[
              { name: 'Shop', link: '#', isActive: false }
            ]}
          />
          <Title title="Your cart" subTitle="" />
        </div>
      </div>
      <div className="container pb-5 mt-n2 mt-md-n3">
        <div className="row">
          <LeftPane>
            <Header className="d-flex flex-wrap justify-content-between align-items-center ">
              <span>Products</span>
              <a className="font-size-sm" href="/shop">
                <Icon name="arrow-back" className="mr-1" />
                Continue shopping
              </a>
            </Header>
            <ShoppingCart items={items} />
          </LeftPane>
          <RightPane>
            <h2 className="h6 px-4 py-3 bg-secondary text-center">Subtotal</h2>
            <div className="h3 font-weight-semibold text-center py-3">
              ${cost}
            </div>
            <hr />
            <a className="btn btn-primary btn-block" href="/checkout/details">
              <i className="icon ion-md-card mr-2" />
              Proceed to Checkout
            </a>
            <div className="pt-4" />
          </RightPane>
        </div>
      </div>
    </Layout>
  )
}

export default connect(state => ({
  items: productItemsWithQuantity(state),
  cost: cartCostSelector(state)
}))(Cart)
