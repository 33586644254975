import { PRODUCT_CHOSEN, PRODUCT_CHOSEN_CLEARED } from '../actions/product'

const initialState = { selectedSku: null }

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_CHOSEN: {
      return { ...state, selectedSku: action.payload }
    }
    case PRODUCT_CHOSEN_CLEARED: {
        return { ...state, selectedSku: null }
      }
    default:
      return state
  }
}
