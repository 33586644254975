export const getDateString = date => {
  var dd = date.getDate()
  var mm = date.getMonth() + 1 //January is 0!

  var yyyy = date.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return yyyy + '-' + mm + '-' + dd
}

export const getHour = hours =>
  hours > 24 ? hours % 24 + 'am': hours > 12 ? hours - 12 + 'pm' : hours + 'am'
