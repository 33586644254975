import { createSelector } from 'reselect'

const cartSelector = state => state.cart.items

export const cartCostSelector = createSelector(
  [cartSelector],
  items =>
    parseFloat(
      items.reduce((a, b) => a + (parseFloat(b.price) || 0), 0)
    ).toFixed(2)
)
