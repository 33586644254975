import React from 'react'
import { Link } from 'react-router-dom'

export default ({ colors }) => {
  return (
    <>
      <label className="d-block">Choose a color</label>

      {colors.map((product, i) => {
        return (
          <div className="custom-control custom-option custom-control-inline mb-2">
            <Link to={`/product/${product.sku}`}>
              <input
                className="custom-control-input"
                type="radio"
                name="color"
                value={product.color}
                id={product.color}
                required
              />
              <label className="custom-option-label" htmlFor="dark">
                <span
                  className="custom-option-color"
                  style={{ background: `url(${product.img_url})` }}
                />
              </label>
            </Link>
          </div>
        )
      })}
      {/* <div className="custom-control custom-option custom-control-inline mb-2">
        <input
          className="custom-control-input"
          type="radio"
          name="color"
          value="dark"
          id="dark"
          required
        />
        <label className="custom-option-label" htmlFor="dark">
          <span
            className="custom-option-color"
            style={{ backgroundColor: ' #2c363f' }}
          />
        </label>
      </div>
      <div className="custom-control custom-option custom-control-inline mb-2">
        <input
          className="custom-control-input"
          type="radio"
          name="color"
          value="red"
          id="red"
          required
        />
        <label className="custom-option-label" htmlFor="red">
          <span
            className="custom-option-color"
            style={{ backgroundColor: ' #e7484d' }}
          />
        </label>
      </div>
      <div className="custom-control custom-option custom-control-inline mb-2">
        <input
          className="custom-control-input"
          type="radio"
          name="color"
          value="white"
          id="white"
          required
        />
        <label className="custom-option-label" htmlFor="white">
          <span
            className="custom-option-color"
            style={{ backgroundColor: ' #e0dfe4' }}
          />
        </label>
      </div>
      <div className="custom-control custom-option custom-control-inline mb-2">
        <input
          className="custom-control-input"
          type="radio"
          name="color"
          value="beige"
          id="beige"
          required
        />
        <label className="custom-option-label" htmlFor="beige">
          <span
            className="custom-option-color"
            style={{ backgroundColor: ' #e6ddd6' }}
          />
        </label>
      </div> */}
    </>
  )
}
