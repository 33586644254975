import React from 'react'

export default ({ id, name }) => {
  return (
    <div className="col-md-4 col-sm-6">
      <div className="card border-0 mb-grid-gutter">
        <a className="card-img-tiles" href={`/shop`}>
          <div className="main-img">
            <img
              src={`https://dummyimage.com/140x142/ededed/ffffff&amp;text=${name}`}
              alt="Caps%2C%20Jacks%20%26%20Collars"
            />
          </div>
          <div className="thumblist">
            <img
              src={`https://dummyimage.com/140x142/ededed/ffffff&amp;text=2`}
              alt=""
            />
            <img
              src={`https://dummyimage.com/140x142/ededed/ffffff&amp;text=1`}
              alt=""
            />
          </div>
        </a>
        <div className="card-body border mt-n1 py-4 text-center">
          <h2 className="h5 mb-1">{name}</h2>
          <span className="d-block mb-3 font-size-xs text-muted">
            Starting from <span className="font-weight-semibold">$49.99</span>
          </span>
          <a
            className="btn btn-pill btn-outline-primary btn-lg"
            href={`/shop`}
          >
            {name}
          </a>
        </div>
      </div>
    </div>
  )
}
