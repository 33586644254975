import { isEmpty, isEmail, isOnlyText } from './validators'

export default form => {
  const {
    deliveryFirstName,
    deliveryLastName,
    deliveryPhone,
    deliveryEmail,
    deliveryAddress1,
    deliveryCity,
    deliveryZip,
    billingEnabled,
    billingFirstName,
    billingLastName,
    billingPhone,
    billingEmail,
    billingAddress1,
    billingCity,
    billingZip
  } = form

  return new Promise((resolve, reject) => {
    const errors = [[], []]
    if (isEmpty(deliveryFirstName)) errors[0].push('First name cannot be empty')
    else if (!isOnlyText(deliveryFirstName))
      errors[0].push('Invalid first name')

    if (isEmpty(deliveryLastName)) errors[0].push('Last name cannot be empty')
    else if (!isOnlyText(deliveryLastName)) errors[0].push('Invalid last name')

    if (isEmpty(deliveryEmail) || !isEmail(deliveryEmail))
      errors[0].push('Email is invalid')

    if (isEmpty(deliveryPhone)) errors[0].push('Phone cannot be empty')

    if (isEmpty(deliveryAddress1)) errors[0].push('Address 1 cannot be empty')

    if (isEmpty(deliveryCity)) errors[0].push('Please choose a city')

    if (isEmpty(deliveryZip)) errors[0].push('Zip code cannot be empty')

    if (billingEnabled) {
      if (isEmpty(billingFirstName))
        errors[1].push('First name cannot be empty')
      else if (!isOnlyText(billingFirstName))
        errors[1].push('Invalid first name')

      if (isEmpty(billingLastName)) errors[1].push('Last name cannot be empty')
      else if (!isOnlyText(billingLastName)) errors[1].push('Invalid last name')

      if (isEmpty(billingEmail) || !isEmail(billingEmail))
        errors[1].push('Email is invalid')

      if (isEmpty(billingPhone)) errors[1].push('Phone cannot be empty')

      if (isEmpty(billingAddress1)) errors[1].push('Address 1 cannot be empty')

      if (isEmpty(billingCity)) errors[1].push('Please choose a city')

      if (isEmpty(billingZip)) errors[1].push('Zip code cannot be empty')
    }

    if (errors[0].length < 1 && errors[1].length < 1) resolve()
    reject(errors)
  })
}
