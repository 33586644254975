import React from 'react'

import NavMenu from './navmenu'
import Footer from './footer'
import Styles from '../../css/index.scss'

export default ({ title, children, username, courier }) => {
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: Styles }} />
      <NavMenu courier={courier} />
      {children}
      <Footer />
    </>
  )
}
