import React, { useState } from 'react'
import Layout from '../components/layout/layout'
import dummyData from '../util/dummyData'

import { Link } from 'react-router-dom'
import FuzzySearch from 'react-fuzzy'

export default ({ products }) => {
  return (
    <Layout>
      <div className="container pb-5 mb-4 pt-5 mt-4" style={{ height: '80vh' }}>
        <div
          className="d-flex justify-content-between align-items-center center-text"
          style={{ margin: '0 15%' }}
        >
          <h3 className="title ">Search</h3>
        </div>
        <div className="body" style={{ margin: '0 15%' }}>
          <div className="body-inner">
            {/* <span
              className="input-group-text"
              id="search-icon"
              style={{
                border: 'none',
                position: 'absolute',
                zIndex: '100',
                background: 'none',
                lineHeight: '2'
              }}
            >
              <i className="icon ion-md-search mr-2" />
            </span> */}
            <FuzzySearch
              list={dummyData()}
              keys={['product', 'category', 'subcategory']}
              width={'100%'}
              style={{
                borderRadius: '6px',
                boxShadow:
                  '0 3px 6px 0 rgba(0,0,0,.1), 0 1px 3px 0 rgba(0,0,0,.08)',
                paddingLeft: '40px',
                height: '48px'
              }}
              placeholder={'Search Products'}
              onSelect={() => console.log('selected')}
              resultsTemplate={(props, state, styles, clickHandler) => {
                return state.results.map((val, i) => {
                  const style =
                    state.selectedIndex === i
                      ? styles.selectedResultStyle
                      : styles.resultsStyle
                  return (
                    <div key={i} style={style}>
                      <Link className="nav-link" to={`/product/${val.sku}`}>
                        <img
                          alt={val.sku}
                          src={val.img_url}
                          style={{ width: '30px' }}
                        />{' '}
                        {val.category}
                        {': '}
                        {'  '}
                        {val.product}
                      </Link>
                    </div>
                  )
                })
              }}
            />
            {/* <div className="input-group pt-3">
              <input
                className="form-control"
                type="text"
                id="site-search"
                placeholder="Search products"
                aria-label="Search products"
                aria-describedby="search-icon"
                style={{
                  borderRadius: '6px',
                  boxShadow:
                    '0 3px 6px 0 rgba(0,0,0,.1), 0 1px 3px 0 rgba(0,0,0,.08)',
                  paddingLeft: '40px',
                  height: '48px'
                }}
              />
            </div>
            <div className="mt-4"></div> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}
