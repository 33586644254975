import React from 'react'
import Layout from '../components/layout/layout'

export default () => {
  return (
    <Layout>
    <div className="modal fade" id="order-details">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Order No - 34VB5540K83</h4>
            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body pb-0">
            <div className="d-sm-flex justify-content-between mb-4 pb-4 border-bottom">
              <div className="media d-block d-sm-flex text-center text-sm-left"><a className="cart-item-thumb mx-auto mr-sm-4" href="shop-single-apparel.html"><img src="img/shop/apparel/cart/01.jpg" alt="Product" /></a>
                <div className="media-body pt-3">
                  <h3 className="product-card-title font-weight-semibold border-0 pb-0"><a href="shop-single-apparel.html">Calvin Klein Jeans Keds</a></h3>
                  <div className="font-size-sm"><span className="text-muted mr-2">Size:</span>8.5</div>
                  <div className="font-size-sm"><span className="text-muted mr-2">Color:</span>Black</div>
                </div>
              </div>
              <div className="pt-2 pt-sm-3 pl-sm-3 mx-auto mx-sm-0 text-center">
                <div className="text-muted mb-sm-2">Quantity:</div>
                <div className="font-weight-semibold">1</div>
              </div>
              <div className="pt-2 pt-sm-3 pl-sm-3 mx-auto mx-sm-0 text-center">
                <div className="text-muted mb-sm-2">Subtotal:</div>
                <div className="font-weight-semibold">$125.00</div>
              </div>
            </div>
            <div className="d-sm-flex justify-content-between mb-4 pb-4 border-bottom">
              <div className="media d-block d-sm-flex text-center text-sm-left"><a className="cart-item-thumb mx-auto mr-sm-4" href="shop-single-apparel.html"><img src="img/shop/apparel/cart/02.jpg" alt="Product"/></a>
                <div className="media-body pt-3">
                  <h3 className="product-card-title font-weight-semibold border-0 pb-0"><a href="shop-single-apparel.html">The North Face Hoodie</a></h3>
                  <div className="font-size-sm"><span className="text-muted mr-2">Size:</span>XL</div>
                  <div className="font-size-sm"><span className="text-muted mr-2">Color:</span>Gray</div>
                </div>
              </div>
              <div className="pt-2 pt-sm-3 pl-sm-3 mx-auto mx-sm-0 text-center">
                <div className="text-muted mb-sm-2">Quantity:</div>
                <div className="font-weight-semibold">1</div>
              </div>
              <div className="pt-2 pt-sm-3 pl-sm-3 mx-auto mx-sm-0 text-center">
                <div className="text-muted mb-sm-2">Subtotal:</div>
                <div className="font-weight-semibold">$134.00</div>
              </div>
            </div>
            <div className="d-sm-flex justify-content-between mb-4 pb-4 border-bottom">
              <div className="media d-block d-sm-flex text-center text-sm-left"><a className="cart-item-thumb mx-auto mr-sm-4" href="shop-single-apparel.html"><img src="img/shop/apparel/cart/03.jpg" alt="Product"/></a>
                <div className="media-body pt-3">
                  <h3 className="product-card-title font-weight-semibold border-0 pb-0"><a href="shop-single-apparel.html">Medicine Chameleon Sunglasses</a></h3>
                  <div className="font-size-sm"><span className="text-muted mr-2">Lenses:</span>Chameleon</div>
                  <div className="font-size-sm"><span className="text-muted mr-2">Frame:</span>Gray / Black</div>
                </div>
              </div>
              <div className="pt-2 pt-sm-3 pl-sm-3 mx-auto mx-sm-0 text-center">
                <div className="text-muted mb-sm-2">Quantity:</div>
                <div className="font-weight-semibold">1</div>
              </div>
              <div className="pt-2 pt-sm-3 pl-sm-3 mx-auto mx-sm-0 text-center">
                <div className="text-muted mb-sm-2">Subtotal:</div>
                <div className="font-weight-semibold">$47.00</div>
              </div>
            </div>
            <div className="d-sm-flex justify-content-between pb-4">
              <div className="media d-block d-sm-flex text-center text-sm-left"><a className="cart-item-thumb mx-auto mr-sm-4" href="shop-single-apparel.html"><img src="img/shop/apparel/cart/04.jpg" alt="Product"/></a>
                <div className="media-body pt-3">
                  <h3 className="product-card-title font-weight-semibold border-0 pb-0"><a href="shop-single-apparel.html">Adidas Performance Hat</a></h3>
                  <div className="font-size-sm"><span className="text-muted mr-2">Material:</span>Acrylic</div>
                  <div className="font-size-sm"><span className="text-muted mr-2">Color:</span>Pink / Dark green</div>
                </div>
              </div>
              <div className="pt-2 pt-sm-3 pl-sm-3 mx-auto mx-sm-0 text-center">
                <div className="text-muted mb-sm-2">Quantity:</div>
                <div className="font-weight-semibold">1</div>
              </div>
              <div className="pt-2 pt-sm-3 pl-sm-3 mx-auto mx-sm-0 text-center">
                <div className="text-muted mb-sm-2">Subtotal:</div>
                <div className="font-weight-semibold">$19.00</div>
              </div>
            </div>
          </div>
          
          
          <div className="modal-footer flex-wrap justify-content-between bg-secondary">
            <div className="px-2 py-1"><span className="text-muted">Subtotal:&nbsp;</span><span className="font-weight-semibold">$325.00</span></div>
            <div className="px-2 py-1"><span className="text-muted">Delivery:&nbsp;</span><span className="font-weight-semibold">$26.50</span></div>
            <div className="px-2 py-1"><span className="text-muted">Tax:&nbsp;</span><span className="font-weight-semibold">$7.25</span></div>
            <div className="px-2 py-1"><span className="text-muted">Total:&nbsp;</span><span className="text-lg font-weight-semibold">$358.75</span></div>
          </div>
        </div>
      </div>
    </div>

    <form className="needs-validation modal fade" method="post" id="open-ticket" tabIndex="-1" noValidate>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Submit new ticket</h4>
            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">
            <p className="text-muted">We normally respond tickets within 2 business days.</p>
            <div className="form-group">
              <label htmlFor="ticket-subject">Subject</label>
              <input className="form-control" type="text" id="ticket-subject" required/>
              <div className="invalid-feedback">Please fill in the subject line!</div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="ticket-type">Type</label>
                  <select className="form-control custom-select" id="ticket-type" required>
                    <option value>Choose type</option>
                    <option value="Website problem">Website problem</option>
                    <option value="Partner request">Partner request</option>
                    <option value="Complaint">Complaint</option>
                    <option value="Info inquiry">Info inquiry</option>
                  </select>
                  <div className="invalid-feedback">Please choose ticket type!</div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="ticket-priority">Priority</label>
                  <select className="form-control custom-select" id="ticket-priority" required>
                    <option value>How urgent is your issue?</option>
                    <option value="Urgent">Urgent</option>
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                  </select>
                  <div className="invalid-feedback">Please choose how urgent your ticket is!</div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="ticket-description">Describe your issue</label>
              <textarea className="form-control" id="ticket-description" rows="8" required></textarea>
              <div className="invalid-feedback">Please provide ticket description!</div>
            </div>
            <div className="custom-file mb-3">
              <input className="custom-file-input" type="file" id="file-input"/>
              <label className="custom-file-label" htmlFor="file-input">Choose file...</label>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" type="submit">Submit Ticket</button>
          </div>
        </div>
      </div>
    </form>
    
    <div className="container pt-lg-3 pb-5 mb-sm-4">
      <div className="row pt-5">
        <div className="col-xl-3 col-lg-4">
          <a className="gallery-item mb-grid-gutter mx-auto" href="/#" style={{maxWidth: "18.75rem"}}><img src="../img/customer.jpg" alt="Daniel Adams"/>
            <div className="gallery-caption">
              <div className="gallery-indicator"><i className="gallery-indicator-icon" data-feather="refresh-ccw"></i></div>Change profile picture
            </div></a>
          <div className="d-none d-lg-block">
            <h6 className="font-size-sm mb-3 pb-2 border-bottom">Technical support</h6>
            <ul className="list-unstyled">
              <li className="font-size-sm mb-2"><i className="text-muted mr-2" data-feather="mail" style={{width: ".875rem", height: ".875rem"}}></i><a className="nav-link-inline" href="mailto:support@example.com">support@example.com</a></li>
              <li className="font-size-sm mb-2"><i className="text-muted mr-2" data-feather="phone" style={{width: ".875rem", height: ".875rem"}}></i><a className="nav-link-inline" href="tel:+100331697720">+1 00 33 169 7720</a></li>
              <li className="font-size-sm mb-2"><i className="text-muted mr-2" data-feather="clock" style={{width: 
              ".875rem", height: ".875rem"}}></i>1-2 business days</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-8 offset-xl-1">
          <div className="d-flex flex-wrap justify-content-between pb-4">
            <div className="pt-3 mr-3">
              <h3 className="mb-0">Daniel Adams</h3><span className="font-size-sm text-warning">d.adams@example.com</span>
            </div>
            
          </div>
          <ul className="list-unstyled border p-3 mb-4">
            <li className="pb-1"><span className="opacity-80">&ndash; Joined:</span><span className="font-weight-semibold ml-1">Jan 13, 2018</span></li>
            <li className="pb-1"><span className="opacity-80">&ndash; Total orders:</span><span className="font-weight-semibold ml-1">15</span></li>
            <li className="pb-1"><span className="opacity-80">&ndash; Total spent:</span><span className="font-weight-semibold ml-1">$5,864</span></li>
            
          </ul>
          <ul className="nav nav-tabs d-none d-sm-flex">
            <li className="nav-item"><a className="nav-link active" href="account-orders.html"><i data-feather="shopping-bag"></i>&nbsp;My orders<span className="badge badge-pill badge-secondary bg-0 border ml-2"><span className="text-primary">1</span></span></a></li>
            <li className="nav-item"><a className="nav-link" href="account-wishlist.html"><i data-feather="heart"></i>&nbsp;Wishlist<span className="badge badge-pill badge-secondary bg-0 border ml-2"><span className="text-primary">3</span></span></a></li>
            {/* IN CASE WE WANT THIS LATER 
            
            <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="/#" data-toggle="dropdown"><i data-feather="settings"></i>&nbsp;Account settings</a>
              <div className="dropdown-menu"><a className="dropdown-item" href="account-profile.html">Profile info</a>
                <div className="dropdown-divider"></div><a className="dropdown-item" href="account-address.html">Addresses</a>
                <div className="dropdown-divider"></div><a className="dropdown-item" href="account-payment.html">Payment methods</a>
              </div>
            </li> */}
          </ul>
          {/* More account/orders info 
          
          <div className="d-sm-none pb-4">
            <button className="btn btn-primary btn-block mb-2" type="button" data-toggle="collapse" data-target="#account-menu"><i className="mr-2" data-feather="menu"></i>Menu</button>
            <div className="collapse" id="account-menu">
              <div className="list-group"><a className="list-group-item list-group-item-action active" href="account-orders.html"><i className="mr-2" data-feather="shopping-bag" style={{width: "1rem", height: "1rem"}}></i>My orders<span className="badge badge-pill badge-secondary bg-0 border ml-2"><span className="text-primary">1</span></span></a><a className="list-group-item list-group-item-action" href="account-wishlist.html"><i className="mr-2" data-feather="heart" style={{width: "1rem", height: "1rem"}}></i>Wishlist<span className="badge badge-pill badge-secondary bg-0 border ml-2"><span className="text-primary">3</span></span></a><a className="list-group-item list-group-item-action" href="account-profile.html"><i className="mr-2" data-feather="user" style={{width: "1rem", height: "1rem"}}></i>Profile info</a><a className="list-group-item list-group-item-action" href="account-address.html"><i className="mr-2" data-feather="map-pin" style={{width: "1rem", height: "1rem"}}></i>Addresses</a><a className="list-group-item list-group-item-action" href="account-payment.html"><i className="mr-2" data-feather="credit-card" style={{width: "1rem", height: "1rem"}}></i>Payment methods</a></div>
            </div>
          </div> */}
         
          <div className="d-flex justify-content-end pb-3">
            <div className="form-inline">
              <label className="mr-3" htmlFor="order-sort">Sort Orders</label>
              <select className="form-control custom-select" id="order-sort">
                <option>All</option>
                <option>Delivered</option>
                <option>In Progress</option>
                <option>Canceled</option>
              </select>
            </div>
          </div>
          <div className="table-responsive font-size-sm">
            <table className="table table-hover mb-0">
              <thead>
                <tr>
                  <th>Order #</th>
                  <th>Date Purchased</th>
                  <th>Status</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><a className="nav-link-inline" href="#order-details" data-toggle="modal">126642</a></td>
                  <td>May 21, 2019</td>
                  <td><span className="badge badge-info m-0">In Progress</span></td>
                  <td>$358.75</td>
                </tr>
                <tr>
                  <td><a className="nav-link-inline" href="#order-details" data-toggle="modal">43CD409</a></td>
                  <td>December 09, 2018</td>
                  <td><span className="badge badge-success m-0">Delivered</span></td>
                  <td><span>$760.50</span></td>
                </tr>
                <tr>
                  <td><a className="nav-link-inline" href="#order-details" data-toggle="modal">5A90V2</a></td>
                  <td>October 15, 2018</td>
                  <td><span className="badge badge-success m-0">Delivered</span></td>
                  <td>$1,264.00</td>
                </tr>
                <tr>
                  <td><a className="nav-link-inline" href="#order-details" data-toggle="modal">7U0981</a></td>
                  <td>July 19, 2018</td>
                  <td><span className="badge badge-success m-0">Delivered</span></td>
                  <td>$198.35</td>
                </tr>
                <tr>
                  <td><a className="nav-link-inline" href="#order-details" data-toggle="modal">872W2</a></td>
                  <td>April 04, 2018</td>
                  <td><span className="badge badge-success m-0">Delivered</span></td>
                  <td>$2,133.90</td>
                </tr>
                <tr>
                  <td><a className="nav-link-inline" href="#order-details" data-toggle="modal">G09F33</a></td>
                  <td>March 30, 2018</td>
                  <td><span className="badge badge-success m-0">Delivered</span></td>
                  <td>$86.40</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr className="pb-4"/>
          <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center justify-content-sm-start mb-0">
              <li className="page-item"><a className="page-link" href="/#">Prev</a></li>
              <li className="page-item d-sm-none"><span className="page-link page-link-static">2 / 5</span></li>
              <li className="page-item d-none d-sm-block"><a className="page-link" href="/#">1</a></li>
              <li className="page-item active d-none d-sm-block" aria-current="page"><span className="page-link">2<span className="sr-only">(current)</span></span></li>
              <li className="page-item d-none d-sm-block"><a className="page-link" href="/#">3</a></li>
              <li className="page-item d-none d-sm-block"><a className="page-link" href="/#">4</a></li>
              <li className="page-item d-none d-sm-block">...</li>
              <li className="page-item d-none d-sm-block"><a className="page-link" href="/#">10</a></li>
              <li className="page-item"><a className="page-link" href="/#">Next</a></li>
            </ul>
          </nav>
          <div className="d-lg-none bg-secondary px-3 py-4 mt-5">
            <h6 className="font-size-sm mb-3 pb-2 border-bottom">Technical support</h6>
            <ul className="list-unstyled">
              <li className="font-size-sm mb-2"><i className="text-muted mr-2" data-feather="mail" style={{width: ".875rem", height: ".875rem"}}></i><a className="nav-link-inline" href="mailto:support@example.com">support@example.com</a></li>
              <li className="font-size-sm mb-2"><i className="text-muted mr-2" data-feather="phone" style={{width: ".875rem", height: ".875rem"}}></i><a className="nav-link-inline" href="tel:+100331697720">+1 00 33 169 7720</a></li>
              <li className="font-size-sm mb-2"><i className="text-muted mr-2" data-feather="clock" style={{width: ".875rem", height: ".875rem"}}></i>1-2 business days</li>
            </ul>
            
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}
