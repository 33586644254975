import React from 'react'
import { connect } from 'react-redux'

import IconLibrary from '../util/IconLibrary'

import { logUserOut } from '../actions/user'

import { getAuthHeader } from '../services/service-helper'

const SignOutButton = ({
  logout,
  username,
  setToastMessage,
  handleShowToast
}) => {
  const handleSignOut = async () => {
    console.log('header token: ', getAuthHeader())
    try {
      await logout(username)
      setToastMessage('Sucessfully Signed Out')
      handleShowToast()
    } catch (e) {
      console.log('There was an error signing out')
    }
  }

  return (
    <a
      className="navbar-btn navbar-collapse-hidden"
      href="#offcanvas-account"
      data-toggle="offcanvas"
      onClick={handleSignOut}
    >
      {IconLibrary.SignOut()}
      <i className="mx-auto mb-1" data-feather="log-out" />
      Sign Out
    </a>
  )
}

export default connect(
  state => {
    const { username } = state.user
    return { username }
  },
  { logout: logUserOut }
)(SignOutButton)
