import { API_URL } from '../config'
import { getToken } from './auth'

export const getApiUrl = method => {
  console.log(API_URL)
  return API_URL + method
}

export const getAuthHeader = () => {
  return {
    authorization: `bearer: ${getToken()}`
  }
}
