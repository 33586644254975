import {
  LOGGING_IN,
  LOGGED_IN,
  LOGIN_ERROR,
  SIGNING_UP,
  SIGNED_UP,
  SIGNUP_ERROR,
  LOGGING_OUT,
  LOGGED_OUT,
  LOGOUT_ERROR
} from '../actions/user'

const initialState = {
  IsLoggingIn: false,
  IsSigningUp: false,
  IsLoggingOut: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return { ...state, IsLoggingIn: true }
    case LOGGED_IN: {
      const { username } = action.payload
      return { ...state, username, IsLoggingIn: false }
    }
    case LOGIN_ERROR: {
      const { errorMessage } = action.payload
      console.log('error message in reducer ', errorMessage)
      return { ...state, errorMessage, IsLoggingIn: false }
    }
    case SIGNING_UP: {
      return { ...state, IsSigningUp: true }
    }
    case SIGNED_UP: {
      return { ...state, IsSigningUp: false }
    }
    case SIGNUP_ERROR: {
      const { errorMessage } = action.payload
      return { ...state, errorMessage, IsSigningUp: false }
    }
    case LOGGING_OUT: {
      return { ...state, IsLoggingOut: true }
    }
    case LOGGED_OUT: {
      const username = undefined
      return { ...state, username, IsLoggingOut: false }
    }
    case LOGOUT_ERROR: {
      const { errorMessage } = action.payload
      return { ...state, errorMessage, IsLoggingOut: false }
    }
    default:
      return state
  }
}
