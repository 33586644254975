import React, { useState } from 'react'
import queryString from 'query-string'

import Layout from '../components/layout/layout'
import Title from '../components/layout/page-title'
import LeftPane from '../components/shop/left-pane'
import RightPane from '../components/shop/right-pane'
import Product from '../components/shop/product'
// import Nav from '../components/shop/nav'
// import QuickView from '../components/shop/quick-view'
import ProductListTopBar from '../components/shop/product-list-top'

import CategoryFilter from '../components/shop/filters/categories'
import PriceRangeFilter from '../components/shop/filters/price-range'
import ManufacturerFilter from '../components/shop/filters/manufacturer'
import SizeFilter from '../components/shop/filters/size'
import dummyData from '../util/dummyData'

import Fetcher from '../components/fetcher'
import { getProducts } from '../services/product'

import { Toast } from 'react-bootstrap'

export default ({ username, location }) => {
  const [showToast, setShowToast] = useState(false)
  //Change this later - set the default to be empty
  const [filteredProducts, setFilteredProducts] = useState([])
  const [products, setProducts] = useState([])
  // const [currentPage, setCurrentPage] = useState(1)

  const { courier } = queryString.parse(location.search)

  const handleShowToast = () => setShowToast(true)

  return (
    <Layout courier={courier}>
      <div
        aria-live="polite"
        aria-atomic="true"
        className="toast-container toast-bottom-center"
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header className="bg-success text-white" closeButton={false}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-check-circle mr-2"
              style={{ width: '1.25rem', height: '1.25rem' }}
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
              <polyline points="22 4 12 14.01 9 11.01" />
            </svg>
            <span className="font-weight-semibold mr-auto">Added to cart!</span>
          </Toast.Header>
          <Toast.Body>This item was added to your cart.</Toast.Body>
        </Toast>
      </div>
      <Fetcher service={getProducts}>
        {({ data }) => {
          setProducts(data)

          return (
            <>
              <Title
                breadCrumbs={[{ name: 'Shop', link: '#', isActive: false }]}
              >
                Products
                <hr className="mt-4" />
              </Title>
              {/* <QuickView /> */}
              <div className="container pb-5 mb-4">
                <div className="row">
                  <LeftPane>
                    <div className="widget widget-categories mb-4 py-1">
                      <CategoryFilter
                        filteredProducts={filteredProducts}
                        setFilteredProducts={setFilteredProducts}
                        products={products}
                      />
                    </div>

                    <div className="widget mb-4 pb-3">
                      <PriceRangeFilter
                        filteredProducts={filteredProducts}
                        setFilteredProducts={setFilteredProducts}
                      />
                    </div>

                    <div className="widget mb-4 pb-1">
                      <ManufacturerFilter
                        filteredProducts={filteredProducts}
                        setFilteredProducts={setFilteredProducts}
                        products={products}
                      />
                    </div>
                  </LeftPane>
                  <RightPane>
                    <ProductListTopBar
                      filteredProducts={filteredProducts}
                      setFilteredProducts={setFilteredProducts}
                    />

                    <div className="row">
                      <hr className="pb-4 mb-2" />
                      {filteredProducts.length
                        ? filteredProducts.map((product, i) => {
                            {
                              console.log('rendering')
                            }
                            {
                              /* if (
                            i >= (currentPage - 1) * 30 &&
                            i < currentPage * 30
                          ) { */
                            }
                            return (
                              <Product
                                key={product.sku}
                                product={product}
                                handleShowToast={handleShowToast}
                              />
                            )
                          })
                        : products.map((product, i) => {
                            {
                              /* if (
                            i >= (currentPage - 1) * 30 &&
                            i < currentPage * 30
                          ) { */
                            }
                            return (
                              <Product
                                key={product.sku}
                                product={product}
                                handleShowToast={handleShowToast}
                              />
                            )
                          })}
                    </div>
                    {/* <div className="row">
                      <hr className="pb-4 mb-2" />
                       <Nav
                        filteredProducts={filteredProducts}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      /> 
                    </div> */}
                  </RightPane>
                </div>
              </div>
            </>
          )
        }}
      </Fetcher>
    </Layout>
  )
}
