import axios from 'axios'

import { getApiUrl, getAuthHeader } from './service-helper'

const tokenKey = 'token'

export const login = async (email, password) =>
  await axios.post(
    `${getApiUrl('sign_in')}${encodeURI(
      `?email=${email}&password=${password}`
    )}`
  )

export const loginGuest = async (email, phone, name) =>
  await axios.post(
    `${getApiUrl('sign_in/guest')}${encodeURI(
      `?email=${email}&phone=${phone}&name=${name}`
    )}`
  )
// } catch (e) {
//   console.log('error in auth:signIn ', e)
//   throw 'error'
// }

export const logout = email =>
  axios.post(
    `${getApiUrl('sign_out')}`,
    { email },
    { headers: getAuthHeader() }
  )

export const signUp = async (email, password, firstName, lastName, phone) =>
  await axios.post(
    `${getApiUrl('sign_up')}${encodeURI(
      `?email=${email}&password=${password}&first_name=${firstName}&last_name=${lastName}&phone=${phone}`
    )}`
  )

export const setToken = token => {
  if (typeof window !== 'undefined') localStorage.setItem(tokenKey, token)
}

export const getToken = () => {
  if (typeof window !== 'undefined') return localStorage.getItem(tokenKey)
}

export const removeToken = () => {
  if (typeof window !== 'undefined') localStorage.removeItem(tokenKey)
}
