import React, { useState, useEffect } from 'react'
import ErrorPage from '../pages/404'

export default ({ service, args, children }) => {
  const [data, setData] = useState()
  useEffect(() => {
    async function fetch() {
      try {
        const response = await (args ? service(...args) : service())
        setData(response.data)
      } catch (e) {
        setData('error')
      }
    }

    fetch()
  }, [service, args])
  if (!data) {
    console.log('here and data: ', data)
    return <React.Fragment />
  }

  if (data === 'error') {
    return <ErrorPage />
  }

  if (typeof children === 'function') {
    console.log('data inside of fetcher ', data)
    return children(data)
  }
  return React.cloneElement(children, { data })
}
