import React from 'react'

export default class IconLibrary {
  static Search() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-search mx-auto mb-1"
      >
        <circle cx="11" cy="11" r="8" />
        <line x1="21" y1="21" x2="16.65" y2="16.65" />
      </svg>
    )
  }

  static SignIn() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-log-in mx-auto mb-1"
      >
        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
        <polyline points="10 17 15 12 10 7" />
        <line x1="15" y1="12" x2="3" y2="12" />
      </svg>
    )
  }

  static SignOut() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-log-in mx-auto mb-1"
      >
        <path d="m135.738281 361.070312c7.140625 8.425782 6.097657 21.046876-2.328125 28.1875-8.4375 7.148438-21.058594 6.085938-28.1875-2.328124l-100.007812-118c-6.378906-7.523438-6.191406-18.558594 0-25.859376l100.007812-118c7.140625-8.429687 19.761719-9.46875 28.1875-2.328124 8.425782 7.140624 9.46875 19.761718 2.328125 28.1875l-72.097656 85.070312h355.042969c11.046875 0 20.003906 8.953125 20.003906 20s-8.957031 20-20.003906 20h-355.042969zm356.761719-361.070312h-173.820312c-11.046876 0-20 8.953125-20 20s8.953124 20 20 20h153.816406v432h-153.816406c-11.046876 0-20 8.953125-20 20s8.953124 20 20 20h173.820312c11.042969 0 20-8.953125 20-20v-472c0-11.046875-8.957031-20-20-20zm0 0" />
        <polyline points="10 17 15 12 10 7" />
        <line x1="15" y1="12" x2="3" y2="12" />
      </svg>
    )
  }

  static ShoppingCart() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-shopping-cart mx-auto mb-1"
      >
        <circle cx="9" cy="21" r="1" />
        <circle cx="20" cy="21" r="1" />
        <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
      </svg>
    )
  }

  static Elipsis() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-more-horizontal"
      >
        <circle cx="12" cy="12" r="1" />
        <circle cx="19" cy="12" r="1" />
        <circle cx="5" cy="12" r="1" />
      </svg>
    )
  }

  static RightArrow() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-chevron-right widget-categories-indicator"
      >
        <polyline points="9 18 15 12 9 6" />
      </svg>
    )
  }

  static Home() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-home"
      >
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
        <polyline points="9 22 9 12 15 12 15 22" />
      </svg>
    )
  }

  static CreditCard() {
    return (
      <div className="card-wrapper" data-jp-card-initialized="true">
        <div className="jp-card-container">
          <div className="jp-card">
            <div className="jp-card-front">
              <div className="jp-card-logo jp-card-elo">
                <div className="e">e</div>
                <div className="l">l</div>
                <div className="o">o</div>
              </div>
              <div className="jp-card-logo jp-card-visa">visa</div>
              <div className="jp-card-logo jp-card-mastercard">MasterCard</div>
              <div className="jp-card-logo jp-card-maestro">Maestro</div>
              <div className="jp-card-logo jp-card-amex" />
              <div className="jp-card-logo jp-card-discover">discover</div>
              <div className="jp-card-logo jp-card-dinersclub" />
              <div className="jp-card-logo jp-card-dankort">
                <div className="dk">
                  <div className="d" />
                  <div className="k" />
                </div>
              </div>
              <div className="jp-card-lower">
                <div className="jp-card-shiny" />
                <div className="jp-card-cvc jp-card-display">•••</div>
                <div className="jp-card-number jp-card-display">
                  •••• •••• •••• ••••
                </div>
                <div className="jp-card-name jp-card-display">Full Name</div>
                <div
                  className="jp-card-expiry jp-card-display"
                  data-before="month/year"
                  data-after="valid
    thru"
                >
                  ••/••
                </div>
              </div>
            </div>
            <div className="jp-card-back">
              <div className="jp-card-bar" />
              <div className="jp-card-cvc jp-card-display">•••</div>
              <div className="jp-card-shiny" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
