export default () => [
  {
    category: 'Shingles',
    color: 'Teak',
    coverage: 33,
    coverage_units: null,
    description:
      'TruDefinition Duration Storm Shingles feature SureNail Technology with Triple Layer Protection and an integrated polymeric backing material that allows them to meet one of the industry’s highest ratings for impact resistance. This shingle is designed for ',
    dimension: '39x13',
    id: 6,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Shingles_Teak.jpeg?49',
    manufacturer: 'Test1',
    price: '39.00',
    product: 'Trudefinition Storm',
    size: null,
    sku: '30151505',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 75,
    weight_units: null
  },
  {
    category: 'Second Category',
    color: 'Onyx Black',
    coverage: 33,
    coverage_units: null,
    description:
      'TruDefinition Duration Storm Shingles feature SureNail Technology with Triple Layer Protection and an integrated polymeric backing material that allows them to meet one of the industry’s highest ratings for impact resistance. This shingle is designed for ',
    dimension: '39x13',
    id: 5,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Shingles_Onyx_Black.jpg?48',
    manufacturer: 'Test3',
    price: '39.00',
    product: 'Trudefinition Storm',
    size: null,
    sku: '30151504',
    subcategory: 'None',
    unit_of_measure: 'BDL',
    weight: 75,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Driftwood',
    coverage: 33,
    coverage_units: null,
    description:
      'TruDefinition Duration Storm Shingles feature SureNail Technology with Triple Layer Protection and an integrated polymeric backing material that allows them to meet one of the industry’s highest ratings for impact resistance. This shingle is designed for ',
    dimension: '39x13',
    id: 1,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Shingle_Driftwood.jpeg?44',
    manufacturer: 'Test2',
    price: '39.00',
    product: 'Trudefinition Storm',
    size: null,
    sku: '30151500',
    subcategory: 'Third Subcat',
    unit_of_measure: 'BDL',
    weight: 75,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Estate Grey',
    coverage: 33,
    coverage_units: null,
    description:
      'TruDefinition Duration Storm Shingles feature SureNail Technology with Triple Layer Protection and an integrated polymeric backing material that allows them to meet one of the industry’s highest ratings for impact resistance. This shingle is designed for ',
    dimension: '39x13',
    id: 2,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Shingle_Estate.jpg?45',
    manufacturer: 'Test3',
    price: '39.00',
    product: 'Trudefinition Storm',
    size: null,
    sku: '30151501',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 75,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Desert Tan',
    coverage: 33,
    coverage_units: null,
    description:
      'TruDefinition Duration Storm Shingles feature SureNail Technology with Triple Layer Protection and an integrated polymeric backing material that allows them to meet one of the industry’s highest ratings for impact resistance. This shingle is designed for ',
    dimension: '39x13',
    id: 3,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Shingle_Dessert.jpg?46',
    manufacturer: 'Test1',
    price: '39.00',
    product: 'Trudefinition Storm',
    size: null,
    sku: '30151502',
    subcategory: 'None',
    unit_of_measure: 'BDL',
    weight: 75,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Antique Silver',
    coverage: 33,
    coverage_units: null,
    description:
      'TruDefinition Duration Storm Shingles feature SureNail Technology with Triple Layer Protection and an integrated polymeric backing material that allows them to meet one of the industry’s highest ratings for impact resistance. This shingle is designed for ',
    dimension: '39x13',
    id: 7,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Singels_Silver.jpeg?50',
    manufacturer: 'Test2',
    price: '39.00',
    product: 'Trudefinition Storm',
    size: null,
    sku: '30151506',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 75,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Brownwood',
    coverage: 33,
    coverage_units: null,
    description:
      'TruDefinition Duration Storm Shingles feature SureNail Technology with Triple Layer Protection and an integrated polymeric backing material that allows them to meet one of the industry’s highest ratings for impact resistance. This shingle is designed for ',
    dimension: '39x13',
    id: 4,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Shingles_Brownwood.jpeg?47',
    manufacturer: 'Test1',
    price: '39.00',
    product: 'Trudefinition Storm',
    size: null,
    sku: '30151503',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 75,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Terra Cotta',
    coverage: 33,
    coverage_units: null,
    description:
      'Galvanized Adjustable B-Vent Roof Jack for B-Vent Stack Roof Penetration. Can be used for Remodeling, Do it yourself and New Residential projects.',
    dimension: '39x13',
    id: 17,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Jack_3.jpg?43',
    manufacturer: 'Test3',
    price: '17.99',
    product: 'TruDef Duration',
    size: null,
    sku: '30151517',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Brownwood',
    coverage: 33,
    coverage_units: null,
    description:
      'Attention to detail matters. New ProEdge™ Hip & Ridge Shingles provide a clean, consistent roof line while offering a cost-effective solution to cutting strip shingles. Make The Right Choice™ with ProEdge™ Hip & Ridge Shingles. Capping a roof is now quick',
    dimension: '39x13',
    id: 8,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Hip_Ridge_Proedge_Proedge_estate_grey.jpg?18',
    manufacturer: 'Test4',
    price: '55.99',
    product: 'TruDef Duration',
    size: null,
    sku: '30151508',
    subcategory: 'None',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Desert Tan',
    coverage: 33,
    coverage_units: null,
    description:
      'Attention to detail matters. New ProEdge™ Hip & Ridge Shingles provide a clean, consistent roof line while offering a cost-effective solution to cutting strip shingles. Make The Right Choice™ with ProEdge™ Hip & Ridge Shingles. Capping a roof is now quick',
    dimension: '39x13',
    id: 10,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Hip_and_Ridge_Brownwood.jpeg?20',
    manufacturer: 'Test1',
    price: '55.99',
    product: 'TruDef Duration',
    size: null,
    sku: '30151510',
    subcategory: 'None',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Driftwood',
    coverage: 33,
    coverage_units: null,
    description:
      'Attention to detail matters. New ProEdge™ Hip & Ridge Shingles provide a clean, consistent roof line while offering a cost-effective solution to cutting strip shingles. Make The Right Choice™ with ProEdge™ Hip & Ridge Shingles. Capping a roof is now quick',
    dimension: '39x13',
    id: 11,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Hip_and_Ridge_Black.jpeg?21',
    manufacturer: 'Test2',
    price: '55.99',
    product: 'TruDef Duration',
    size: null,
    sku: '30151511',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Teak',
    coverage: 33,
    coverage_units: null,
    description:
      'This Drip Edge Flashing provides protection from water damage along the eaves and rake of a roof. The extra “kick” at the bottom of the face directs run-off away from the structure. Drip Edge Flashing can be used with asphalt shingles or metal roofing pan',
    dimension: '39x13',
    id: 12,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Metal_Flashing_White.jpeg?24',
    manufacturer: 'Test2',
    price: '6.02',
    product: 'TruDef Duration',
    size: null,
    sku: '30151512',
    subcategory: 'none',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Onyx Black',
    coverage: 33,
    coverage_units: null,
    description:
      'This Drip Edge Flashing provides protection from water damage along the eaves and rake of a roof. The extra “kick” at the bottom of the face directs run-off away from the structure. Drip Edge Flashing can be used with asphalt shingles or metal roofing pan',
    dimension: '39x13',
    id: 13,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Metal_Flashing_Brown.jpeg?25',
    manufacturer: 'Test4',
    price: '4.70',
    product: 'TruDef Duration',
    size: null,
    sku: '30151513',
    subcategory: 'none',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Sierra Gray',
    coverage: 33,
    coverage_units: null,
    description:
      'This Drip Edge Flashing provides protection from water damage along the eaves and rake of a roof. The extra “kick” at the bottom of the face directs run-off away from the structure. Drip Edge Flashing can be used with asphalt shingles or metal roofing pan',
    dimension: '39x13',
    id: 14,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Metal_Flashing_Black.jpeg?26',
    manufacturer: 'Test5',
    price: '6.99',
    product: 'TruDef Duration',
    size: null,
    sku: '30151514',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Estate Grey',
    coverage: 33,
    coverage_units: null,
    description:
      'Attention to detail matters. New ProEdge™ Hip & Ridge Shingles provide a clean, consistent roof line while offering a cost-effective solution to cutting strip shingles. Make The Right Choice™ with ProEdge™ Hip & Ridge Shingles. Capping a roof is now quick',
    dimension: '39x13',
    id: 9,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Hip_and_Ridge_Sierra_Grey.jpg?19',
    manufacturer: 'Test5',
    price: '55.99',
    product: 'TruDef Duration',
    size: null,
    sku: '30151509',
    subcategory: 'none',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Quarry Gray',
    coverage: 33,
    coverage_units: null,
    description:
      'This Drip Edge Flashing provides protection from water damage along the eaves and rake of a roof. The extra “kick” at the bottom of the face directs run-off away from the structure. Drip Edge Flashing can be used with asphalt shingles or metal roofing pan',
    dimension: '39x13',
    id: 15,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Metal_Flashing_Slate_Grey.jpeg?27',
    manufacturer: 'Test6',
    price: '6.99',
    product: 'TruDef Duration',
    size: null,
    sku: '30151515',
    subcategory: 'none',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Shasta White',
    coverage: 33,
    coverage_units: null,
    description:
      'This Drip Edge Flashing provides protection from water damage along the eaves and rake of a roof. The extra “kick” at the bottom of the face directs run-off away from the structure. Drip Edge Flashing can be used with asphalt shingles or metal roofing pan',
    dimension: '39x13',
    id: 16,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Metal_Flashing_Almodnd.jpeg?28',
    manufacturer: 'Test5',
    price: '6.99',
    product: 'TruDef Duration',
    size: null,
    sku: '30151516',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Onyx Black',
    coverage: 33,
    coverage_units: null,
    description:
      "With it's patent pending design, the P60 PRO provides a full 60 square inches of net free area. Made with the same tried and proven polypropylene material that has been used in Lomanco Omni Ridge Series vents for over 20 years, the P60 will not dent or cr",
    dimension: '39x13',
    id: 23,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Vent_Brown.jpeg?30',
    manufacturer: 'Test2',
    price: '21.99',
    product: 'Oakridge',
    size: null,
    sku: '30151523',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Shasta White',
    coverage: 33,
    coverage_units: null,
    description:
      "With it's patent pending design, the P60 PRO provides a full 60 square inches of net free area. Made with the same tried and proven polypropylene material that has been used in Lomanco Omni Ridge Series vents for over 20 years, the P60 will not dent or cr",
    dimension: '39x13',
    id: 24,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Vent_Black.jpeg?31',
    manufacturer: 'Test1',
    price: '21.99',
    product: 'Oakridge',
    size: null,
    sku: '30151524',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Sierra Gray',
    coverage: 33,
    coverage_units: null,
    description:
      'Galvanized Adjustable B-Vent Roof Jack for B-Vent Stack Roof Penetration. Can be used for Remodeling, Do it yourself and New Residential projects.',
    dimension: '39x13',
    id: 21,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Jack_3.jpg?43',
    manufacturer: 'Test3',
    price: '35.99',
    product: 'Oakridge',
    size: null,
    sku: '30151521',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Teak',
    coverage: 33,
    coverage_units: null,
    description:
      "With it's patent pending design, the P60 PRO provides a full 60 square inches of net free area. Made with the same tried and proven polypropylene material that has been used in Lomanco Omni Ridge Series vents for over 20 years, the P60 will not dent or cr",
    dimension: '39x13',
    id: 25,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Vent_White.jpeg?32',
    manufacturer: 'Test6',
    price: '21.99',
    product: 'Oakridge',
    size: null,
    sku: '30151525',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Amber',
    coverage: 33,
    coverage_units: null,
    description:
      'Ventilators: Type - Roof Vent; Dimensions - 16.75 x 18.75 in.; Additional Information - Slant Galvanized, Net Free Area: 50 sq-in/pc; Manufacturer Series - Airhawk',
    dimension: '39x13',
    id: 26,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Airvent.jpeg?42',
    manufacturer: 'Test7',
    price: '21.99',
    product: 'Oakridge',
    size: null,
    sku: '30151526',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Estate Grey',
    coverage: 33,
    coverage_units: null,
    description:
      'Galvanized Adjustable B-Vent Roof Jack for B-Vent Stack Roof Penetration. Can be used for Remodeling, Do it yourself and New Residential projects.',
    dimension: '39x13',
    id: 18,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Jack_3.jpg?43',
    manufacturer: 'Test7',
    price: '19.99',
    product: 'Oakridge',
    size: null,
    sku: '30151518',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Driftwood',
    coverage: 33,
    coverage_units: null,
    description:
      'Galvanized Adjustable B-Vent Roof Jack for B-Vent Stack Roof Penetration. Can be used for Remodeling, Do it yourself and New Residential projects.',
    dimension: '39x13',
    id: 19,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Jack_3.jpg?43',
    manufacturer: 'Test8',
    price: '22.99',
    product: 'Oakridge',
    size: null,
    sku: '30151519',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Desert Tan',
    coverage: 33,
    coverage_units: null,
    description:
      'Galvanized Adjustable B-Vent Roof Jack for B-Vent Stack Roof Penetration. Can be used for Remodeling, Do it yourself and New Residential projects.',
    dimension: '39x13',
    id: 20,
    img_url:
      'https://cdn2.shopify.com/s/files/1/0068/1856/2151/files/Jack_3.jpg?43',
    manufacturer: 'Test8',
    price: '27.99',
    product: 'Oakridge',
    size: null,
    sku: '30151520',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  },
  {
    category: 'Shingles',
    color: 'Brownwood',
    coverage: 33,
    coverage_units: null,
    description:
      "With it's patent pending design, the P60 PRO provides a full 60 square inches of net free area. Made with the same tried and proven polypropylene material that has been used in Lomanco Omni Ridge Series vents for over 20 years, the P60 will not dent or cr",
    dimension: '39x13',
    id: 22,
    img_url:
      'https://cdn.shopify.com/s/files/1/0068/1856/2151/files/Vent_Bronze.jpeg?29',
    manufacturer: 'Test8',
    price: '21.99',
    product: 'Oakridge',
    size: null,
    sku: '30151522',
    subcategory: 'Shingles',
    unit_of_measure: 'BDL',
    weight: 68,
    weight_units: null
  }
]
