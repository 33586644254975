import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { addToCart } from '../../actions/cart'
import Icon from '../ioicon'
// import QuickView from './quick-view'

const Product = ({ product, products, addProductToCart, handleShowToast }) => {
  const [show, setShow] = useState(false)
  const { sku, description, img_url, price, color, size } = product
  const title = product.product
  const handleAddToCart = product => () => {
    addProductToCart(product)
    handleShowToast()
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      {/* <QuickView handleClose={handleClose} show={show} /> */}
      <div className="col-md-4 col-sm-6">
        <div className="product-card mb-4" style={{ maxWidth: '12rem' }}>
          <div className="product-thumb" style={{ cursor: 'pointer' }}>
            <Link className="product-thumb-link" to={`/product/${sku}`} />

            <img src={img_url} alt="Product" style={{ height: '192px' }} />
          </div>
          <div className="product-card-body text-center">
            <Link
              className="product-thumb-link"
              to={{
                pathname: `/product/${sku}`
              }}
            >
              {/* <span className="product-meta">{sku}</span> */}
              <h6>
                <span>{title}</span>
              </h6>
              {color ? (
                <h5 className="product-card-title">
                  <span>{color}</span>
                </h5>
              ) : (
                ''
              )}
              {size ? (
                <h5 className="product-card-title">
                  <span>{size}</span>
                </h5>
              ) : (
                ''
              )}

              <span className="text-primary">${price}</span>
            </Link>
            <button
              className="btn btn-primary btn-block"
              style={{ marginTop: '8px' }}
              type="button"
              data-toggle="toast"
              data-target="#cart-toast"
              onClick={handleAddToCart(product)}
            >
              Add to cart
            </button>
          </div>
          {/* <div className="product-card-body body-hidden">
            <div className="pb-2 text-center"> */}
          {/* DISABLING FOR DEMO <div className="custom-control custom-option custom-control-inline mb-2">
                <input
                  className="custom-control-input"
                  type="radio"
                  name="size-1"
                  id="xl-1"
                />
                <label className="custom-option-label" htmlFor="xs-1">
                  XS
                </label>
              </div>
              <div className="custom-control custom-option custom-control-inline mb-2">
                <input
                  className="custom-control-input"
                  type="radio"
                  name="size-1"
                  id="l-1"
                  defaultChecked
                />
                <label className="custom-option-label" htmlFor="s-1">
                  S
                </label>
              </div>
              <div className="custom-control custom-option custom-control-inline mb-2">
                <input
                  className="custom-control-input"
                  type="radio"
                  name="size-1"
                  id="m-1"
                />
                <label className="custom-option-label" htmlFor="m-1">
                  M
                </label>
              </div>
              <div className="custom-control custom-option custom-control-inline mb-2">
                <input
                  className="custom-control-input"
                  type="radio"
                  name="size-1"
                  id="s-1"
                />
                <label className="custom-option-label" htmlFor="l-1">
                  L
                </label>
              </div> 
              <div className="custom-control custom-option custom-control-inline mb-2">
                <input
                  className="custom-control-input"
                  type="radio"
                  name="size-1"
                  id="xs-1"
                />
                <label className="custom-option-label" htmlFor="xl-1">
                  XL
                </label>
              </div> */}
          {/* </div> */}

          {/* <button
              className="quick-view-btn btn-block"
              data-toggle="modal"
              data-target="#quick-view"
              onClick={handleShow}
              style={{ paddingTop: '8px' }}
            >
              <i className="icon ion-md-eye mr-1" />
              Quick View
            </button> */}
          {/* </div> */}
        </div>
      </div>
    </>
  )
}

export default connect(
  null,
  { addProductToCart: addToCart }
)(Product)
