import React from 'react'
import { Link } from 'react-router-dom'

export default () => {
  return (
    <>
      <footer className="page-footer bg-dark">
        {/*<div className="pt-5 pb-0 pb-md-4">
          <div className="container">
            <div className="row">
               {/*<div className="col-md-4 col-sm-6">
                <div className="widget widget-links pb-4">
                  <h3 className="widget-title text-white border-light">
                    Categories
                  </h3>
                  <ul>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Shingles</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Hip &amp; Ridge</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Metal Flashing</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">
                          Caps, Jacks &amp; Collars
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Ventilation</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Fasteners</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">
                          Video Paints &amp; Caulking
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Ice &amp; Water</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Underlayments</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="widget widget-links pb-4">
                  <h3 className="widget-title text-white border-light">
                    Account &amp; delivery info
                  </h3>
                  <ul>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Your account</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">
                          Delivery &amp; policies
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">
                          Refunds &amp; replacements
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Taxes</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Delivery info</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="widget widget-links pb-4">
                  <h3 className="widget-title text-white border-light">
                    About us
                  </h3>
                  <ul>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Careers</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">About shop</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Our stores</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">Services</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link-inline nav-link-light" to="#">
                        <i className="widget-categories-indicator icon ion-md-arrow-dropright" />
                        <span className="font-size-sm">News</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
        {/* <div className="col-xl-4 offset-xl-1 col-md-5">
                <div className="widget">
                  <h3 className="widget-title text-white border-light">
                    Stay informed
                  </h3>
                  <form
                    className="validate pb-4"
                    action="https://studio.us12.list-manage.com/subscribe/post-json?u=c7103e2c981361a6639545bd5&amp;amp;id=29ca296126&amp;c=?"
                    method="get"
                    name="mc-embedded-subscribe-form"
                    id="mc-embedded-subscribe-form"
                  >
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{ backgroundColor: '#e8e8e8' }}
                        >
                          <i className="icon ion-md-mail" />
                        </span>
                      </div>
                      <input
                        className="form-control border-0 box-shadow-0 bg-secondary"
                        type="email"
                        name="EMAIL"
                        id="mce-EMAIL"
                        defaultValue=""
                        placeholder="Your email"
                        required
                      />
                    </div>
                    <div
                      style={{ position: 'absolute', left: '-5000px' }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_c7103e2c981361a6639545bd5_29ca296126"
                        tabIndex="-1"
                      />
                    </div>
                    <button
                      className="btn btn-primary btn-block"
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                    >
                      Subscribe*
                    </button>
                    <p
                      className="font-size-xs text-white opacity-60 pt-2 mb-2"
                      id="mc-helper"
                    >
                      *Subscribe to our newsletter to receive early discount
                      offers, updates and new products info.
                    </p>
                    <div className="subscribe-status" />
                  </form>
                  <div className="widget pb-4">
                    <h3 className="widget-title text-white border-light">
                      Download our app
                    </h3>
                    <Link
                      className="market-btn market-btn-light apple-btn mr-2 mb-2"
                      to="#"
                      role="button"
                    >
                      <span className="market-button-subtitle">
                        Download on the
                      </span>
                      <span className="market-button-title">App Store</span>
                    </Link>
                    <Link
                      className="market-btn market-btn-light google-btn"
                      to="#"
                      role="button"
                    >
                      <span className="market-button-subtitle">
                        Download on the
                      </span>
                      <span className="market-button-title">Google Play</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="pt-5 pb-0 pb-md-5 border-bottom border-light"
          id="shop-features"
          style={{ backgroundColor: '#1f1f1f' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6 border-right border-light">
                <div className="icon-box text-center mb-5 mb-md-0">
                  <div className="icon-box-icon">
                    <i
                      className="icon ion-md-car"
                      style={{ lineHeight: '68px' }}
                    />
                  </div>
                  <h3 className="icon-box-title font-weight-semibold text-white">
                    Delivery
                  </h3>
                  <p className="icon-box-text">
                    Delivery anywhere in Denver Colorado
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 border-right border-light">
                <div className="icon-box text-center mb-5 mb-md-0">
                  <div className="icon-box-icon">
                    <i
                      className="icon ion-md-refresh"
                      style={{ lineHeight: '68px' }}
                    />
                  </div>
                  <h3 className="icon-box-title font-weight-semibold text-white">
                    Our Gurantee
                  </h3>
                  <p className="icon-box-text">We guarantee delivery</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 border-right border-light">
                <div className="icon-box text-center mb-5 mb-md-0">
                  <div className="icon-box-icon">
                    <i
                      className="icon ion-md-help-buoy"
                      style={{ lineHeight: '68px' }}
                    />
                  </div>
                  <h3 className="icon-box-title font-weight-semibold text-white">
                    Customer service
                  </h3>
                  <p className="icon-box-text">Friendly Customer support</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="icon-box text-center mb-5 mb-md-0">
                  <div className="icon-box-icon">
                    <i
                      className="icon ion-md-card"
                      style={{ lineHeight: '68px' }}
                    />
                  </div>
                  <h3 className="icon-box-title font-weight-semibold text-white">
                    Secure online payment
                  </h3>
                  <p className="icon-box-text">
                    We posess SSL / Secure сertificate
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 pb-4" style={{ backgroundColor: '#1f1f1f' }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 text-center text-sm-left">
                <div className="mb-4 mb-sm-0">
                  <Link className="d-inline-block" to="index.html">
                    MDROP
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 text-center text-sm-right">
                <Link
                  className="social-btn sb-facebook sb-light mx-1 mb-2"
                  to="#"
                >
                  <i className="icon ion-logo-facebook" />
                </Link>
                <Link
                  className="social-btn sb-twitter sb-light mx-1 mb-2"
                  to="#"
                >
                  <i className="icon ion-logo-twitter" />
                </Link>
                <Link
                  className="social-btn sb-instagram sb-light mx-1 mb-2"
                  to="#"
                >
                  <i className="icon ion-logo-instagram" />
                </Link>
                <Link className="social-btn sb-vimeo sb-light mx-1 mb-2" to="#">
                  <i className="icon ion-logo-vimeo" />
                </Link>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-sm-6 text-center text-sm-left">
                <ul className="list-inline font-size-sm">
                  <li className="list-inline-item mr-3">
                    <Link className="nav-link-inline nav-link-light" to="#">
                      Outlets
                    </Link>
                  </li>
                  <li className="list-inline-item mr-3">
                    <Link className="nav-link-inline nav-link-light" to="#">
                      Affiliates
                    </Link>
                  </li>
                  <li className="list-inline-item mr-3">
                    <Link className="nav-link-inline nav-link-light" to="#">
                      Support
                    </Link>
                  </li>
                  <li className="list-inline-item mr-3">
                    <Link className="nav-link-inline nav-link-light" to="#">
                      Privacy
                    </Link>
                  </li>
                  <li className="list-inline-item mr-3">
                    <Link className="nav-link-inline nav-link-light" to="#">
                      Terms of use
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 text-center text-sm-right">
                <div className="d-inline-block">
                  <img width="187" src="/img/cards.png" alt="Payment methods" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-3" style={{ backgroundColor: '#1a1a1a' }}>
          <div
            className="container font-size-xs text-center"
            aria-label="Copyright"
          >
            <span className="text-white opacity-60 mr-1">© MDROP 2019</span>
          </div>
        </div>
        {/* <Link
          className="scroll-to-top-btn show"
          to="#"
          style={{ color: 'white' }}
        >
          <i className="icon ion-md-arrow-up"> </i>
        </Link> */}
      </footer>
    </>
  )
}
