import React from 'react'

import Layout from '../components/layout/layout'
import Title from '../components/layout/page-title'
import CategoryItem from '../components/categories/category-item'
import Fetcher from '../components/fetcher'
import { getCategories } from '../services/categories'

export default () => (
  <Layout>
    <Title breadCrumbs={[{ name: 'Categories', link: '#', isActive: false }]}>
      Categories
      <hr className="mt-4" />
    </Title>
    <div className="container pb-5 mb-4">
      <div className="row">
        <Fetcher service={getCategories}>
          {({ data }) => {
            return data.map(category => {
              const { id, name } = category
              return <CategoryItem key={id} id={id} name={name} />
            })
          }}
        </Fetcher>
        <CategoryItem id="1" name="Caps, Jacks &amp; Collars" />

        <div className="col-md-4 col-sm-6">
          <div className="card border-0 mb-grid-gutter">
            <a className="card-img-tiles" href="shop-style1-ls.html">
              <div className="main-img">
                <img
                  src="https://dummyimage.com/140x142/ededed/ffffff&amp;text=Ice%20%26%20Water"
                  alt="Ice &amp; Water"
                />
              </div>
              <div className="thumblist">
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=1"
                  alt=""
                />
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=2"
                  alt=""
                />
              </div>
            </a>
            <div className="card-body border mt-n1 py-4 text-center">
              <h2 className="h5 mb-1">Ice &amp; Water</h2>
              <span className="d-block mb-3 font-size-xs text-muted">
                Starting from{' '}
                <span className="font-weight-semibold">$56.00</span>
              </span>
              <a
                className="btn btn-pill btn-outline-primary btn-lg"
                href="shop-style1-ls.html"
              >
                Shop Ice & Water
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6">
          <div className="card border-0 mb-grid-gutter">
            <a className="card-img-tiles" href="shop-style1-ls.html">
              <div className="main-img">
                <img
                  src="https://dummyimage.com/140x142/ededed/ffffff&amp;text=Metal%20Flashing"
                  alt="Metal Flashing"
                />
              </div>
              <div className="thumblist">
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=1"
                  alt=""
                />
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=2"
                  alt=""
                />
              </div>
            </a>
            <div className="card-body border mt-n1 py-4 text-center">
              <h2 className="h5 mb-1">Metal Flashing</h2>
              <span className="d-block mb-3 font-size-xs text-muted">
                Starting from{' '}
                <span className="font-weight-semibold">$27.00</span>
              </span>
              <a
                className="btn btn-pill btn-outline-primary btn-lg"
                href="shop-style1-ls.html"
              >
                Shop Metal Flashing
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6">
          <div className="card border-0 mb-grid-gutter">
            <a className="card-img-tiles" href="shop-style1-ls.html">
              <div className="main-img">
                <img
                  src="https://dummyimage.com/140x142/ededed/ffffff&amp;text=Paints%20%26%20Caulking"
                  alt="Paints &amp; Caulking"
                />
              </div>
              <div className="thumblist">
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=1"
                  alt=""
                />
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=2"
                  alt=""
                />
              </div>
            </a>
            <div className="card-body border mt-n1 py-4 text-center">
              <h2 className="h5 mb-1">Paints &amp; Caulking</h2>
              <span className="d-block mb-3 font-size-xs text-muted">
                Starting from{' '}
                <span className="font-weight-semibold">$35.99</span>
              </span>
              <a
                className="btn btn-pill btn-outline-primary btn-lg"
                href="shop-style1-ls.html"
              >
                Shop Paints &amp; Caulking
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6">
          <div className="card border-0 mb-grid-gutter">
            <a className="card-img-tiles" href="shop-style1-ls.html">
              <div className="main-img">
                <img
                  src="https://dummyimage.com/140x142/ededed/ffffff&amp;text=Fasteners"
                  alt="Fasteners"
                />
              </div>
              <div className="thumblist">
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=1"
                  alt=""
                />
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=2"
                  alt=""
                />
              </div>
            </a>
            <div className="card-body border mt-n1 py-4 text-center">
              <h2 className="h5 mb-1">Fasteners</h2>
              <span className="d-block mb-3 font-size-xs text-muted">
                Starting from{' '}
                <span className="font-weight-semibold">$14.50</span>
              </span>
              <a
                className="btn btn-pill btn-outline-primary btn-lg"
                href="shop-style1-ls.html"
              >
                Shop Fasteners
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6">
          <div className="card border-0 mb-grid-gutter">
            <a className="card-img-tiles" href="shop-style1-ls.html">
              <div className="main-img">
                <img
                  src="https://dummyimage.com/140x142/ededed/ffffff&amp;text=Hip%20%26%20Ridge"
                  alt="Hip &amp; Ridge"
                />
              </div>
              <div className="thumblist">
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=1"
                  alt=""
                />
                <img
                  src="https://dummyimage.com/48x48/ededed/ffffff&amp;text=2"
                  alt=""
                />
              </div>
            </a>
            <div className="card-body border mt-n1 py-4 text-center">
              <h2 className="h5 mb-1">Hip &amp; Ridge</h2>
              <span className="d-block mb-3 font-size-xs text-muted">
                Starting from{' '}
                <span className="font-weight-semibold">$10.45</span>
              </span>
              <a
                className="btn btn-pill btn-outline-primary btn-lg"
                href="shop-style1-ls.html"
              >
                Shop Hip &amp; Ridge
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
