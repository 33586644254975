import {
  login,
  loginGuest,
  signUp,
  logout,
  setToken,
  removeToken
} from '../services/auth'

export const LOGGING_IN = 'LOGGING_IN'
export const LOGGED_IN = 'LOGGED_IN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGGING_OUT = 'LOGGING_OUT'
export const LOGGED_OUT = 'LOGGED_OUT'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'
export const SIGNING_UP = 'SIGNING_UP'
export const SIGNED_UP = 'SIGNED_UP'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'

const loggingIn = () => ({
  type: LOGGING_IN
})

const loggedIn = username => ({
  type: LOGGED_IN,
  payload: { username }
})

const loginError = errorMessage => ({
  type: LOGIN_ERROR,
  payload: { errorMessage }
})

const loggingOut = () => ({
  type: LOGGING_OUT
})

const loggedOut = () => ({
  type: LOGGED_OUT
})

const logoutError = errorMessage => ({
  type: LOGOUT_ERROR,
  payload: { errorMessage }
})

const signingUp = () => ({
  type: SIGNING_UP
})

const signedUp = () => ({
  type: SIGNED_UP
})

const signUpError = errorMessage => ({
  type: SIGNUP_ERROR,
  payload: { errorMessage }
})

export const logUserIn = (email, password) => async dispatch => {
  dispatch(loggingIn())
  try {
    const { data } = await login(email, password)
    dispatch(loggedIn(email))
    setToken(data.data.token)
  } catch (error) {
    console.log('in the action dispatch, error: ', error)
    dispatch(loginError(error))
    throw 'error'
  }
}

export const logUserInGuest = (email, phone, name) => async dispatch => {
  dispatch(loggingIn())
  try {
    const { data } = await loginGuest(email, phone, name)
    dispatch(loggedIn(email))
    setToken(data.data.token)
  } catch (error) {
    console.log('in the action dispatch, error: ', error)
    dispatch(loginError(error))
    throw 'error'
  }
}

export const logUserOut = username => async dispatch => {
  dispatch(loggingOut())
  try {
    await logout(username)
    removeToken()
    dispatch(loggedOut())
  } catch (error) {
    dispatch(logoutError(error))
  }
}

export const signUserUp = (
  email,
  password,
  firstName,
  lastName,
  phone
) => async dispatch => {
  dispatch(signingUp())
  try {
    await signUp(email, password, firstName, lastName, phone)
    dispatch(signedUp(email))
  } catch (error) {
    dispatch(signUpError(error))
    throw 'error'
  }
}
