import React from 'react'

import Layout from '../components/layout/layout'
import Fetcher from '../components/fetcher'
import { getProducts } from '../services/product'
import ProductSimple from '../components/product/product-simple'
import ProductCarousel from '../components/product/product-carousel'
import ColorPicker from '../components/product/color-picker'
import AddToCart from '../components/product/addtocart'
import ProductInformation from '../components/product/product-information'

export default ({ match, location, history }) => {
  const chosenSku = match.params.id

  return (
    <Layout>
      <Fetcher service={getProducts}>
        {({ data }) => {
          const product = data.find(x => x.sku === chosenSku)
          const products = data

          const differentColorProducts = products.filter(filteredItem => {
            return (
              filteredItem.product === product.product && product.color !== null
            )
          })

          const differentSizeProducts = products.filter(filteredItem => {
            return (
              filteredItem.product === product.product && product.size !== null
            )
          })

          console.log('list of colors: ', differentColorProducts)
          console.log('list of sizes: ', differentSizeProducts)

          if (!product) {
            return (
              <div>
                <h1>Sorry, that product does not exist</h1>
              </div>
            )
          }

          return (
            <div className="container pb-5">
              {window.scrollTo(0, 0)}
              <div className="row pt-5">
                <div className="col-lg-6">
                  <div className="d-lg-none pb-2">
                    <ProductSimple product={product} />
                  </div>
                  {/* <ProductCarousel product={product} /> */}
                  <div style={{ maxWidth: '550px' }}>
                    <img src={product.img_url} style={{ width: '100%' }} />
                  </div>
                </div>
                <div className="col-lg-5 pt-4 pt-lg-0">
                  <div className="d-none d-lg-block">
                    <ProductSimple product={product} />
                  </div>
                  <form className="pb-4">
                    {differentColorProducts.length ? (
                      <div className="form-group mb-1">
                        <ColorPicker colors={differentColorProducts} />
                      </div>
                    ) : (
                      ''
                    )}
                    {differentSizeProducts.length ? (
                      <div className="form-group">
                        <select
                          className="form-control "
                          id="size"
                          name="size"
                          onChange={e => {
                            history.push(`/product/${e.target.value}`)
                          }}
                          required
                        >
                          <option value>- Select a size</option>
                          {differentSizeProducts.map((product, i) => {
                            return (
                              <option value={product.sku}>
                                {product.size}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="d-flex flex-wrap align-items-center pt-1">
                      <AddToCart product={product} />
                    </div>
                  </form>
                  <ProductInformation product={product} />
                  <nav className="entry-navigation" />
                </div>
              </div>
            </div>
          )
        }}
      </Fetcher>
    </Layout>
  )
}
