import React from 'react'

export default ({ children, subTitle }) => {
  return (
    <React.Fragment>
      <h1 className="page-title pt-1">{children}</h1>
      {subTitle ? (
        <h2 className="h1 text-primary font-weight-light">{subTitle}</h2>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}
