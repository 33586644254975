import React from 'react'

export default ({ children, ...props }) => {
  return (
    <div className="col-lg-3" {...props}>    
      <div className="offcanvas-sidebar">
        <div className="offcanvas-sidebar-body">
          <div className="offcanvas-sidebar-body-inner">{children}</div>
        </div>
      </div>
    </div>
  )
}
