import { UPDATE_FORM } from '../actions/form'



export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FORM: {
      const { key, value } = action.payload
      return { ...state, [key]: value }
    }
    default:
      return state
  }
}
